import { MouseEvent, ReactNode, forwardRef, useState } from 'react';

import { MenuOptionItem } from 'components/menu';
import { TruncateText } from 'components/typography';
import { StyledProps, x } from 'style';

export type SelectMenuItemProps = StyledProps & {
  value?: any;
  isChecked?: boolean;
  focusSelect?: boolean;
  children?: ReactNode;
  closeOnClick?: boolean | ((e: MouseEvent) => boolean);
  onSelect?(value: any): void;
  onFocusSelect?(value: any): void;
};

export const SelectMenuItem = forwardRef<HTMLDivElement, SelectMenuItemProps>(
  function SelectMenuItem(props, ref) {
    const {
      focusSelect = false,
      children,
      closeOnClick = false,
      isChecked = false,
      value,
      onSelect,
      onFocusSelect,
      ...otherProps
    } = props;
    const [isHovered, setHovered] = useState(false);

    const handleItemClick = () => {
      onSelect?.(value);
    };

    const handleFocusClick = (e: MouseEvent) => {
      e.stopPropagation();

      onFocusSelect?.(value);
    };

    return (
      <MenuOptionItem
        ref={ref}
        isChecked={isChecked}
        value={value}
        closeOnClick={closeOnClick}
        onClick={handleItemClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        {...(otherProps as any)}
      >
        <TruncateText flexGrow={1}>{children}</TruncateText>

        {focusSelect ? (
          <x.a
            visibility={isHovered ? 'visible' : 'hidden'}
            overflow="hidden"
            flexShrink={0}
            fontSize="sm"
            color="primary-500"
            role="button"
            w={isHovered ? 'auto' : 0}
            onClick={handleFocusClick}
          >
            Only
          </x.a>
        ) : null}
      </MenuOptionItem>
    );
  }
);
