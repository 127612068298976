import { RefObject, useCallback, useEffect, useRef } from 'react';

export interface UseOutsideClickProps {
  /**
   * The node ref.
   */
  ref: RefObject<HTMLElement | null | undefined>;

  /**
   * Handler function called when an outside click is detected.
   */
  handler: (e: Event) => void;

  /**
   * Whether the listener is enabled.
   */
  enabled?: boolean;
}

/**
 * Detects when a click event is fired outside of the specified element ref.
 *
 * @example
 * import React from 'react';
 * import { useOutsideClick } from 'hooks';
 *
 * const Component = () => {
 *  const menuRef = React.useRef();
 *  const [isOpen, setOpen] = React.useState(false);
 *
 *  useOutsideClick({
 *    ref: menuRef,
 *    handler: () => setOpen(false),
 *  });
 *
 *  return (
 *    <>
 *      <button onClick={() => setOpen(true)}>Open menu</button>
 *      {isOpen ? <div ref={menuRef}>Menu</div> : null}
 *    </>
 *  )
 * };
 */
export function useOutsideClick(props: UseOutsideClickProps) {
  const { ref, handler, enabled = true } = props;
  const handlerRef = useRef(handler);

  const callback = useCallback(
    (e: Event) => {
      if (!ref.current?.contains(e.target as Element)) {
        handlerRef.current(e);
      }
    },
    [ref]
  );

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (enabled) {
      document.addEventListener('click', callback, true);
      document.addEventListener('touchstart', callback, true);

      return () => {
        document.removeEventListener('click', callback, true);
        document.removeEventListener('touchstart', callback, true);
      };
    }
  }, [ref, enabled, callback]);
}

export type UseOutsideClickReturn = ReturnType<typeof useOutsideClick>;
