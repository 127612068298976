import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { ReactElement, Ref, forwardRef } from 'react';

import { TransitionDefaults, Variants, withDelay } from './utils';

const variants: Variants = {
  enter: ({ transition, transitionEnd, delay } = {}) => ({
    scale: 1,
    opacity: 1,
    visibility: 'visible',
    transition: transition?.enter ?? withDelay.enter(TransitionDefaults.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ transition, transitionEnd, delay } = {}) => ({
    opacity: 0,
    scale: 1,
    transition: transition?.exit ?? withDelay.exit(TransitionDefaults.exit, delay),
    transitionEnd: {
      ...transitionEnd?.exit,
      visibility: 'hidden',
    },
  }),
  initial: () => ({
    opacity: 0,
    scale: 0.9,
  }),
};

export const scaleFadeConfig: HTMLMotionProps<'div'> = {
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  variants,
};

export type ScaleFadeProps = HTMLMotionProps<'div'> & {
  children?:
    | ReactElement
    | ((motionProps: HTMLMotionProps<'div'>, ref: Ref<HTMLDivElement>) => ReactElement);
  in?: boolean;
};

export type ScaleFadePresenceProps = ScaleFadeProps & {
  unmountOnExit?: boolean;
};

export const ScaleFade = forwardRef<HTMLDivElement, ScaleFadeProps>(function ScaleFade(props, ref) {
  const { in: isOpen, children, transition, ...rest } = props;

  const custom = {
    transition,
  };

  const animate = isOpen ? 'enter' : 'exit';

  const motionProps = {
    custom,
    ...scaleFadeConfig,
    animate,
    ...rest,
  };

  return typeof children === 'function' ? (
    children(motionProps, ref)
  ) : (
    <motion.div {...motionProps}>{children}</motion.div>
  );
});

export const ScaleFadePresence = forwardRef<HTMLDivElement, ScaleFadePresenceProps>(
  function ScaleFade(props, ref) {
    const { in: isOpen, unmountOnExit, transition, ...rest } = props;

    const custom = {
      transition,
    };

    const show = unmountOnExit ? isOpen && unmountOnExit : true;

    return (
      <AnimatePresence custom={custom}>
        {show && <ScaleFade in={isOpen} custom={custom} {...rest} />}
      </AnimatePresence>
    );
  }
);
