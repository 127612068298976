import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface TabIndicatorProps extends StyledProps {}

export const TabIndicator = forwardRef<HTMLDivElement, TabIndicatorProps>(
  function TabIndicatorWithRef(props, ref) {
    return (
      <x.div
        ref={ref}
        h="2px"
        bottom={0}
        position="absolute"
        bg="primary-500"
        transition="150ms ease-in-out"
        transitionProperty="width, left"
        {...props}
      />
    );
  }
);
