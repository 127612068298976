import { styled } from 'style';

export const ButtonGroup = styled.div`
  vertical-align: middle;
  position: relative;
  display: inline-flex;

  > *:first-of-type:not(:last-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > *:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
  }

  > *:not(:first-of-type):last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
