import { useTh } from '@xstyled/emotion';
import { ReactNode } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';

import { Avatar } from 'components/avatar';
import { IconButton, IconButtonLink } from 'components/button';
import { SvgIcon } from 'components/icon';
import { ReactComponent as Bell } from 'components/icon/lindua/bell2.svg';
import { ReactComponent as Compass } from 'components/icon/lindua/compass3.svg';
import { ReactComponent as Anvil } from 'components/icon/lindua/equalizer2.svg';

import { Logo } from 'components/logo';
import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuLink,
  MenuList,
  MenuTitle,
  MenuTrigger,
} from 'components/menu';
import { Tooltip } from 'components/tooltip';
import { x } from 'style';

const LOGO_SIZE = '28px';
const ICON_SIZE = '24px';
const AVATAR_SIZE = 8;

type GlobalNavProps = {
  children?: ReactNode;
  orientation: 'horizontal' | 'vertical';
  onDrawerSelect?(drawer: string): void;
  onPreferences?(): void;
  onSignOut?(): void;
};

export const GlobalNav = (props: GlobalNavProps) => {
  const { children, orientation, onDrawerSelect, onSignOut, onPreferences } = props;

  const customLogo = useTh('logo') as string;
  const flexDirection = orientation === 'vertical' ? 'column' : 'row';

  useHotkeys('ctrl+q', () => onDrawerSelect?.('quickNavigation'));

  return (
    <x.nav
      justifyContent="space-between"
      flexDirection={flexDirection}
      alignItems="center"
      flexShrink={0}
      display="flex"
      color="components.navbar.text"
      bg="components.navbar.background"
      py={orientation === 'vertical' ? 5 : undefined}
      px={orientation === 'horizontal' ? 5 : undefined}
      w={orientation === 'vertical' ? '4rem' : undefined}
      h={orientation === 'horizontal' ? '4rem' : undefined}
    >
      <x.div
        flexDirection={flexDirection}
        justifyContent="center"
        alignItems="center"
        display="flex"
        gap={6}
      >
        <x.div>
          <Link to="/">
            <Logo
              url={!customLogo || customLogo === 'default' ? undefined : customLogo}
              size={LOGO_SIZE}
            />
          </Link>
        </x.div>

        <Tooltip placement={'auto'} label="Quick navigation (Ctrl + Q)">
          <IconButton
            aria-label="Quick navigation"
            onClick={() => onDrawerSelect?.('quickNavigation')}
          >
            <SvgIcon svg={<Compass />} size={ICON_SIZE} />
          </IconButton>
        </Tooltip>
      </x.div>

      <x.div
        flexDirection={flexDirection}
        justifyContent="center"
        alignItems="center"
        display="flex"
        gap={3}
      >
        <IconButtonLink to="/admin" aria-label="Admin">
          <SvgIcon svg={<Anvil />} size={ICON_SIZE} />
        </IconButtonLink>

        <Tooltip placement={'auto'} label="Notifications">
          <IconButton aria-label="Notifications" onClick={() => onDrawerSelect?.('notifications')}>
            <SvgIcon svg={<Bell />} size={ICON_SIZE} />
          </IconButton>
        </Tooltip>

        <x.div>
          <MenuTrigger>
            <Avatar size={AVATAR_SIZE} bg="white" fallbackText="User" isRounded />

            <Menu>
              <MenuList>
                <MenuTitle>User</MenuTitle>

                <MenuLink to="/account">Profile</MenuLink>
                <MenuItem onClick={onPreferences}>Preferences</MenuItem>

                <MenuDivider />

                <MenuItem onClick={onSignOut}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </MenuTrigger>
        </x.div>
      </x.div>

      {children}
    </x.nav>
  );
};
