import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

const BASE_SIZE = 44;

export type SpinnerProps = StyledProps & {
  size?: number | string;

  strokeWidth?: number;

  color?: string;

  isIndeterminate?: boolean;
};

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(function Spinner(props, ref) {
  const {
    color = 'currentColor',
    size = 40,
    strokeWidth = 3.6,
    isIndeterminate = false,
    ...styledProps
  } = props;

  const animationProps = getAnimationProps(isIndeterminate);

  return (
    <x.div color={color} ref={ref} display="inline-block" h={size} w={size} {...styledProps}>
      <motion.svg
        role="progressbar"
        focusable={false}
        viewBox={`${BASE_SIZE / 2} ${BASE_SIZE / 2} ${BASE_SIZE} ${BASE_SIZE}`}
        display="block"
        style={{
          originX: '50%',
          originY: '50%',
        }}
        {...animationProps.svg}
      >
        <motion.circle
          strokeDashoffset={0}
          strokeDasharray="80px, 200px"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="none"
          cx="100%"
          cy="100%"
          r={(BASE_SIZE - strokeWidth) / 2}
          {...animationProps.circle}
        />
      </motion.svg>
    </x.div>
  );
});

function getAnimationProps(isIndeterminate: boolean) {
  let svg = {};
  let circle = {};

  if (isIndeterminate) {
    svg = {
      animate: { rotate: [0, 360] },
      transition: { duration: 1.4, ease: 'linear', repeat: Infinity },
    };

    circle = {
      animate: {
        strokeDashoffset: [0, -15, -125],
        strokeDasharray: ['1px, 200px', '100px, 200px', '100px, 200px'],
      },
      transition: { duration: 1.4, ease: 'easeInOut', repeat: Infinity, repeatDelay: 0.1 },
    };
  }

  return { svg, circle };
}
