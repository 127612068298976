import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import { authClient } from 'auth';

export class ApiClient {
  readonly #axios: AxiosInstance;

  constructor(baseUrl = `${process.env.REACT_APP_DECISIO_PATH}/api`) {
    this.#axios = axios.create({
      baseURL: baseUrl,
    });

    this.#axios.interceptors.request.use(async (config) => {
      const accessToken = await authClient.getAccessToken();
      if (accessToken !== null) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }

      return config;
    });
  }

  /**
   * Performs a DELETE request to the specified URL.
   * @param url The request URL.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'DELETE', config).then((res) => res.data);
  }

  /**
   * Performs a GET request to the specified URL.
   * @param url The request URL.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'GET', config).then((res) => res.data);
  }

  /**
   * Performs a POST request to the specified URL.
   * @param url The request URL.
   * @param data The request data.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async post<T, D = any>(url: string, data: D, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'POST', { ...config, data }).then((res) => res.data);
  }

  /**
   * Performs a PUT request to the specified URL.
   * @param url The request URL.
   * @param data The request data.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async put<T, D = any>(url: string, data: D, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'PUT', { ...config, data }).then((res) => res.data);
  }

  /**
   * Performs a PATCH request to the specified URL.
   * @param url The request URL.
   * @param data The request data.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async patch<T, D = any>(url: string, data: D, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'PATCH', { ...config, data }).then((res) => res.data);
  }

  /**
   * Performs a request to the specified URL.
   * @param url The request URL.
   * @param method The request method.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async request<T>(
    url: string,
    method: Method,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.#axios.request<T>({ url, method, ...config });
  }
}

export function toFormData<T extends Record<string, any>>(value: T): FormData {
  return Object.keys(value).reduce((acc, key) => {
    acc.append(key, value[key]);
    return acc;
  }, new FormData());
}

export const api = new ApiClient();
