import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { x } from 'style';

export interface TabListScrollButtonProps {
  direction?: 'next' | 'previous';
  isDisabled?: boolean;
  onClick?(): void;
}

export function TabListScrollButton(props: TabListScrollButtonProps) {
  const { direction = 'previous', isDisabled = false, onClick } = props;

  return (
    <x.div
      role="button"
      w={12}
      display="flex"
      alignItems="center"
      justifyContent="center"
      opacity={isDisabled ? '0.5' : '1'}
      onClick={onClick}
    >
      {direction === 'previous' ? <FiChevronLeft /> : <FiChevronRight />}
    </x.div>
  );
}
