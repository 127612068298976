import { Children, ReactElement, ReactNode, forwardRef, isValidElement, useState } from 'react';

import { useControlledState } from 'hooks';
import { StyledProps, x } from 'style';
import { clampValue } from 'utils';

import { TabProps } from './Tab';
import { TabLabel } from './TabLabel';
import { TabList } from './TabList';
import { TabPanel } from './TabPanel';

export type TabsProps = StyledProps & {
  children?: ReactNode;

  selectedIndex?: number;

  initialSelectedIndex?: number;

  hideTabList?: boolean;

  showTabListOnHover?: boolean;

  onSelectedIndexChange?(index: number): void;
};

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(function Tabs(props, ref) {
  const {
    children: childrenProp = [],
    selectedIndex: selectedIndexProp,
    initialSelectedIndex = 0,
    hideTabList = false,
    showTabListOnHover = true,
    onSelectedIndexChange,
    ...styledProps
  } = props;

  const [selectedIndex, setSelectedIndex] = useControlledState({
    value: selectedIndexProp,
    defaultValue: initialSelectedIndex,
    onChange: onSelectedIndexChange,
  });

  const [peakTabList, setPeakTabList] = useState(false);
  const showTabList = !hideTabList || peakTabList;

  const handleTabListMouseEnter = () => {
    if (showTabListOnHover) {
      setPeakTabList(true);
    }
  };

  const handleTabListMouseLeave = () => {
    if (showTabListOnHover) {
      setPeakTabList(false);
    }
  };

  const children = Children.toArray(childrenProp).filter((child) =>
    isValidElement(child)
  ) as ReactElement<TabProps>[];

  const clampedSelectedIndex = clampValue(selectedIndex, children.length);

  // Map the children to their corresponding tab labels.
  const tabLabels: ReactElement[] = [];
  let tabPanel: ReactElement | null = null;

  children.forEach((child, index) => {
    const props = (child as ReactElement).props as TabProps;
    let key = child.key || index;
    tabLabels.push(
      <TabLabel
        key={key}
        isStretched={props.stretchLabel}
        isDismissible={props.isDismissible}
        onDismiss={props.onDismiss}
      >
        {props.label}
      </TabLabel>
    );

    if (index === clampedSelectedIndex) {
      tabPanel = <TabPanel key={key}>{props.children}</TabPanel>;
    }
  });

  return (
    <x.div ref={ref} position="relative" {...styledProps}>
      {showTabList ? (
        <TabList
          selectedIndex={clampedSelectedIndex}
          onSelectedIndexChange={setSelectedIndex}
          onMouseLeave={handleTabListMouseLeave}
        >
          {tabLabels}
        </TabList>
      ) : (
        <x.div
          position="absolute"
          aria-hidden="true"
          transform
          translateY="-1rem"
          h="2rem"
          w="100%"
          onMouseEnter={handleTabListMouseEnter}
        />
      )}

      {tabPanel}
    </x.div>
  );
});
