import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';

import * as applications from 'api/applications';
import {
  Application,
  ApplicationId,
  CreateApplicationPayload,
  OrganizationGroupId,
  TransferApplicationPayload,
  UpdateApplicationDashboardConfigurationPayload,
  UpdateApplicationGroupsPayload,
  UpdateApplicationPayload,
} from 'interfaces';

/**
 * Hook to get all applications.
 * @param options The query options.
 */
export function useApplicationsQuery(options: UseQueryOptions<Application[]> = {}) {
  const { data } = useQuery('applications', () => applications.getApplications(), options);

  return {
    applications: data!,
  };
}

export function useApplicationQuery(
  applicationPath: string,
  options: UseQueryOptions<Application> = {}
) {
  applicationPath = applicationPath?.toLocaleLowerCase();

  const queryClient = useQueryClient();
  const { data } = useQuery(
    ['applications', applicationPath],
    () => applications.getApplication(applicationPath),
    {
      ...options,
      initialData: () => {
        return queryClient
          .getQueryData<Application[]>('applications')
          ?.find((w) => w.slug.toLocaleLowerCase() === applicationPath);
      },
    }
  );

  return {
    application: data!,
  };
}

export function useCreateApplication() {
  const mutation = useMutation(
    (payload: CreateApplicationPayload) => applications.createApplication(payload),
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

export type UseUpdateApplicationMutationPayload = UpdateApplicationPayload & {
  applicationId: ApplicationId;
};

export function useUpdateApplicationMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ applicationId, ...payload }: UseUpdateApplicationMutationPayload) =>
      applications.updateApplication(applicationId, payload),
    {
      onSuccess: (application) => {
        queryClient.setQueryData<Application[]>(['applications'], (applications) => {
          return (
            applications?.map((x) => (x.id === application.id ? application : x)) || [application]
          );
        });
      },
    }
  );
}

export function useTransferApplication(applicationId: ApplicationId) {
  const mutation = useMutation(
    (payload: TransferApplicationPayload) =>
      applications.transferApplication(applicationId, payload),
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

export function useGetApplicationResources(
  applicationId: ApplicationId,
  organizationGroupId: OrganizationGroupId
) {
  const { data } = useQuery(['applications', applicationId, 'resources', organizationGroupId], () =>
    applications.getApplicationResources(applicationId, organizationGroupId)
  );

  return {
    applicationResources: data!,
  };
}

export function useUpdateApplicationResources(
  applicationId: ApplicationId,
  organizationGroupId: OrganizationGroupId
) {
  const mutation = useMutation(
    (payload: UpdateApplicationPayload) =>
      applications.updateApplicationResources(applicationId, organizationGroupId, payload),
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

export function useApplicationGroupsQuery(applicationId: ApplicationId) {
  const { data } = useQuery(['applications', applicationId, 'groups'], () =>
    applications.getApplicationGroups(applicationId)
  );

  return {
    applicationGroups: data!,
  };
}

export function useUpdateApplicationGroups(applicationId: ApplicationId) {
  const mutation = useMutation(
    (payload: UpdateApplicationGroupsPayload) =>
      applications.updateApplicationGroups(applicationId, payload),
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

/**
 * Hook used to query the application dashboard configuration.
 * @param applicationId The application ID.
 * @returns
 */
export function useApplicationDashboardConfigurationQuery(applicationId: ApplicationId) {
  const { data } = useQuery(['applications', applicationId, 'dashboard-configuration'], () =>
    applications.getApplicationDashboardConfiguration(applicationId)
  );

  return {
    dashboardConfiguration: data!,
  };
}

/**
 * Hook that returns a mutation used to update the application dashboard configuration.
 * @param applicationId The application ID.
 * @returns The `UseMutationResult`.
 */
export function useUpdateApplicationDashboardConfiguration(applicationId: ApplicationId) {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateApplicationDashboardConfigurationPayload) =>
      applications.updateApplicationDashboardConfiguration(applicationId, payload),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          'applications',
          applicationId,
          'dashboard-configuration',
        ]);
      },
    }
  );
}
