import { Paginator } from 'components/paginator';
import { x } from 'style';

import { useEntityListContext } from './EntityListContext';

export function EntityListPagination() {
  const { query, pageCount, setPage } = useEntityListContext();

  if (!pageCount) {
    return null;
  }

  return (
    <x.div my={6} display="flex" justifyContent="center">
      <Paginator page={query.page} pageCount={pageCount} onPageChange={setPage} />
    </x.div>
  );
}
