import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { ScaleFade } from 'components/transitions';
import { popperCSSVars } from 'hooks';
import { StyledProps, styled } from 'style';

import { usePopoverContext } from './popoverContext';

export const StyledPopover = styled.div({
  borderRadius: 'md',
  background: 'white',
  boxShadow: 'xs, lg',

  transformOrigin: `${popperCSSVars.transformOrigin.varRef}`,
  outline: 'none',

  maxHeight: `${popperCSSVars.maxHeight.varRef}`,
  width: '96',
});

const AnimatedPopover = motion(StyledPopover);

export type PopoverProps = StyledProps & {};

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(function Popover(props, ref) {
  const { isOpen } = usePopoverContext();

  return (
    <ScaleFade in={isOpen}>
      {(motionProps: any) => <AnimatedPopover ref={ref} {...motionProps} {...props} />}
    </ScaleFade>
  );
});
