import { ReactNode, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useForceUpdate } from 'hooks/useForceUpdate';
import { createContext } from 'utils';

export const [PortalContextProvider, usePortalContext] = createContext<HTMLDivElement | null>({
  strict: false,
  name: 'PortalContext',
});

export interface PortalProps {
  children?: ReactNode;
}

export const DefaultPortal = (props: PortalProps) => {
  const { children } = props;

  const forceUpdate = useForceUpdate();

  const tempNode = useRef<HTMLElement | null>(null);
  const portal = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!tempNode.current) {
      return;
    }

    const host = document.body;
    // const parentPortal = usePortalContext();

    portal.current = document.createElement('div');
    host.appendChild(portal.current!);

    forceUpdate();

    const portalNode = portal.current;
    return () => {
      if (host.contains(portalNode)) {
        host.removeChild(portalNode!);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const portal = doc.createElement()

  return portal.current ? (
    createPortal(
      <PortalContextProvider value={portal.current}>{children}</PortalContextProvider>,
      portal.current
    )
  ) : (
    <span ref={tempNode} />
  );
};

export const Portal = (props: PortalProps) => {
  return <DefaultPortal {...props} />;
};
