import {
  ApplicationId,
  CreateReportPayload,
  Report,
  ReportEmbed,
  ReportId,
  UpdateReportPayload,
} from 'interfaces';

import { api } from './api';

/**
 * Fetches the reports belonging to the specified application.
 * @param applicationId The application ID.
 */
export function getReports(applicationId: ApplicationId) {
  return api.get<Report[]>(`/applications/${applicationId}/reports`);
}

/**
 * Creates a new report for the specified application.
 * @param applicationId The application ID.
 * @param payload The request payload used to create the report.
 */
export function createReport(applicationId: ApplicationId, payload: CreateReportPayload) {
  return api.post<Report>(
    `/applications/${applicationId}/reports`,
    convertPayloadToFormData(payload)
  );
}

/**
 * Deletes an existing report belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId the report ID.
 */
export function deleteReport(applicationId: ApplicationId, reportId: ReportId) {
  return api.delete(`/applications/${applicationId}/reports/${reportId}`);
}

/**
 * Fetches a single report belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 */
export function getReport(applicationId: ApplicationId, reportId: ReportId) {
  return api.get<Report>(`/applications/${applicationId}/reports/${reportId}`);
}

/**
 * Fetches the embed data for a report belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 */
export async function getReportEmbed(applicationId: ApplicationId, reportId: ReportId) {
  const { data } = await api.get<{ data: ReportEmbed }>(
    `/applications/${applicationId}/reports/${reportId}/embed`
  );

  return data;
}

/**
 * Updates an existing report belonging to the specified application.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 * @param payload The request payload used to update the report.
 */
export function updateReport(
  applicationId: ApplicationId,
  reportId: ReportId,
  payload: UpdateReportPayload
) {
  return api.put<Report>(
    `/applications/${applicationId}/reports/${reportId}`,
    convertPayloadToFormData(payload)
  );
}

/**
 * Adds or removes the report as a favorite for the current user.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 */
export function setReportFavorited(
  applicationId: ApplicationId,
  reportId: ReportId,
  isFavorite: boolean
) {
  return api.patch(`/applications/${applicationId}/reports/${reportId}/favorite`, {
    isFavorite,
  });
}

/**
 * Sets the tags for the specified report.
 * @param applicationId The application ID.
 * @param reportId The report ID.
 * @param tagIds The tag IDs.
 */
export function setReportTags(applicationId: ApplicationId, reportId: ReportId, tagIds: number[]) {
  return api.patch<Report>(`/applications/${applicationId}/reports/${reportId}/tags`, { tagIds });
}

/**
 * Converts the given payload object to a `FormData` payload.
 * @param payload The payload to convert.
 */
export function convertPayloadToFormData(payload: any): FormData {
  return Object.keys(payload).reduce((data: FormData, key: string): FormData => {
    if (payload[key]) {
      const value = payload[key];
      if (Array.isArray(value)) {
        value.forEach((v, index) => {
          data.append(`${key}[${index}]`, v);
        });
      } else {
        data.append(key, value);
      }
    }
    return data;
  }, new FormData());
}
