import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { Button, ButtonProps } from './Button';

export interface ButtonLinkProps extends ButtonProps, Pick<LinkProps, 'to'> {}

/**
 * Component that renders a `Button` as a `Link` element.
 */
export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(function (props, ref) {
  return <Button as={Link} {...props} />;
});
