import {
  EntityList,
  EntityListContextProvider,
  EntityListPagination,
  EntityListSearch,
  EntityListSort,
  useEntityList,
} from 'components/entity-list';
import { Stack } from 'components/layout';
import { Link } from 'components/link';
import { ListItem, ListItemMeta } from 'components/list';
import { Application } from 'interfaces';
import { x } from 'style';

export type ApplicationListProps = {
  applications: Application[];
};

export function ApplicationList(props: ApplicationListProps) {
  const entityList = useEntityList({
    source: props.applications,
    searchKeys: ['name'],
    sorting: [
      {
        key: 'name',
        label: 'Name',
      },
    ],
  });

  return (
    <EntityListContextProvider value={entityList}>
      <Stack mb={6} spaceBetween>
        <x.div flex="0 1 45%">
          <EntityListSearch />
        </x.div>
      </Stack>

      <Stack mb={6} spaceBetween>
        <x.div>
          <EntityListSort />
        </x.div>
      </Stack>

      <EntityList>
        {(item: Application) => (
          <ListItem key={item.id}>
            <x.div flex="1 1 auto">
              <Link to={`/${item.fullPath}`} fontSize="lg">
                {item.name}
              </Link>

              <ListItemMeta>{item.organization.name}</ListItemMeta>
            </x.div>

            <x.div fontSize="sm" display="flex" divideX={1} divideColor="gray-400">
              <Link to={`/${item.fullPath}/dashboard`} px={3}>
                Dashboard
              </Link>

              <Link to={`/${item.fullPath}/scenarios`} px={3}>
                Scenarios
              </Link>

              <Link to={`/${item.fullPath}/reports`} px={3}>
                Reports
              </Link>

              <Link to={`/${item.fullPath}/mimic-boards`} px={3}>
                Mimic boards
              </Link>
            </x.div>
          </ListItem>
        )}
      </EntityList>

      <EntityListPagination />
    </EntityListContextProvider>
  );
}
