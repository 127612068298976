import { ReactNode } from 'react';

export interface TabProps {
  /**
   * The tab content.
   */
  children?: ReactNode;

  /**
   * The tab label.
   */
  label?: string;

  /**
   * Whether or not the label is stretched.
   */
  stretchLabel?: boolean;

  /**
   * Whether the tab is dismissible.
   */
  isDismissible?: boolean;

  /**
   * Event handler triggered when a dismissible tab is dismissed.
   */
  onDismiss?(): void;
}

export function Tab(props: TabProps) {
  return null;
}
