import { ChangeEvent, ReactNode, forwardRef, useCallback } from 'react';

import { VisuallyHidden } from 'components/visually-hidden';
import { useControlledState, useId } from 'hooks';
import { styled, x } from 'style';

const StyledSwitchLabel = styled.label({
  flexDirection: 'row',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '3',

  cursor: 'pointer',
});

const StyledSwitchCheckbox = styled.div({
  backgroundColor: 'gray-300',

  borderRadius: 'full',
  position: 'relative',

  width: '9',
  height: '18px',
  padding: '2px',

  '&[data-checked]': {
    backgroundColor: 'primary-500',
  },
});

const StyledSwitchIndicator = styled.div({
  backgroundColor: 'white',

  borderRadius: 'full',

  height: '14px',
  width: '14px',

  transition: 'transform ease-in-out 80ms',

  transform: 'translateX(0)',

  '[data-checked] &': {
    transform: 'translateX(100%)',
  },
});

export type SwitchProps = {
  children?: ReactNode;
  id?: string;
  isChecked?: boolean;
  defaultChecked?: boolean;
  isDisabled?: boolean;
  onChange?(isChecked: boolean): void;
};

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  props,
  forwardedRef
) {
  const { children, id, isChecked: isCheckedProp, defaultChecked, isDisabled, onChange } = props;

  const [isChecked, setChecked] = useControlledState({
    value: isCheckedProp,
    defaultValue: Boolean(defaultChecked),
    onChange,
  });

  const switchId = useId(id);
  const inputId = `${switchId}-input`;

  const onInputChange = useCallback(
    (e: ChangeEvent) => {
      if (!isDisabled) {
        let nextValue = !isChecked;

        setChecked(nextValue);
      }
    },
    [isChecked, isDisabled, setChecked]
  );

  return (
    <StyledSwitchLabel htmlFor={inputId}>
      <VisuallyHidden>
        <x.input
          ref={forwardedRef}
          type="checkbox"
          role="switch"
          id={inputId}
          onChange={onInputChange}
        />
      </VisuallyHidden>

      <StyledSwitchCheckbox data-checked={isChecked ? '' : undefined}>
        <StyledSwitchIndicator />
      </StyledSwitchCheckbox>

      <x.div>{children}</x.div>
    </StyledSwitchLabel>
  );
});
