import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface ListProps extends StyledProps {
  hideDivider?: boolean;
}

export const List = forwardRef<HTMLDivElement, ListProps>(function ListWithRef(props, ref) {
  const { hideDivider = false, ...styleProps } = props;

  const dividerProps = hideDivider
    ? {}
    : {
        divideY: 1,
        divideColor: 'gray-200',
      };

  return <x.div ref={ref} {...dividerProps} {...styleProps} />;
});
