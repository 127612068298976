import { ReactNode, forwardRef } from 'react';

import { styled } from 'style';
import type * as Polymorphic from 'style/polymorphic';

const DEFAULT_TAG = 'div';

const StyledMenuTitle = styled(DEFAULT_TAG)({
  textTransform: 'uppercase',
  lineHeight: 1.5,
  fontSize: 'sm',
  color: 'gray-500',
  userSelect: 'none',

  padding: '2 3',
});

export type MenuTitleProps = {
  children?: ReactNode;
};

export const MenuTitle = forwardRef(function MenuTitle(props, forwardedRef) {
  return <StyledMenuTitle ref={forwardedRef} {...props} />;
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, MenuTitleProps>;
