import { forwardRef } from 'react';

import { HTMLProps, SystemProps, x } from 'style';

export type TextareaProps = HTMLProps<'textarea'> & SystemProps;

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  props,
  ref
) {
  return (
    <x.textarea
      ref={ref}
      bg="white"
      borderRadius="md"
      lineHeight={6}
      boxShadow="sm"
      display="block"
      outline="none"
      border="1px solid"
      borderColor={{ _: 'gray-300', focus: 'primary-500' }}
      color={{ _: 'gray-600', placeholder: 'gray-400' }}
      ringColor={{ _: 'primary-500' }}
      ring={{ _: 0, focus: 1 }}
      px={3}
      py={2}
      w="full"
      resize="vertical"
      {...props}
    />
  );
});
