import { Children, cloneElement, forwardRef, isValidElement } from 'react';

import { useSelectionState } from 'hooks';

import { MenuList, MenuListProps } from './MenuList';

export type MenuOptionGroupProps = MenuListProps & {
  value?: any;
  defaultValue?: any;
  type?: 'checkbox' | 'radio';
  onChange?(value: any): void;
};

export const MenuOptionGroup = forwardRef<HTMLDivElement, MenuOptionGroupProps>(
  function MenuOptionGroup(props, forwardedRef) {
    const {
      value,
      defaultValue,
      type = 'radio',
      children: childrenProp,
      onChange,
      ...rest
    } = props;

    const multiple = type === 'checkbox';
    const { toggle, select, isSelected } = useSelectionState({
      initialValue: value ?? defaultValue,
      multiple,
      onChange,
    });

    const children = Children.map(childrenProp, (child) => {
      if (!isValidElement(child)) {
        return;
      }

      const onClick = (event: MouseEvent) => {
        multiple ? toggle(child.props.value) : select(child.props.value);
        child.props?.onClick?.(event);
      };

      const isChecked = isSelected(child.props.value);

      return cloneElement(child, {
        type: 'radio',
        isChecked,
        onClick,
      });
    });

    return (
      <MenuList ref={forwardedRef} {...rest}>
        {children}
      </MenuList>
    );
  }
);
