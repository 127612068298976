import { ReactNode, forwardRef } from 'react';

import { ModalFocusScope, useModalContext } from 'components/modal';
import { x } from 'style';

import { DialogContent } from './DialogContent';
import { DialogSize } from './types';

export type DialogContainerProps = {
  children?: ReactNode;

  size?: DialogSize;
};

export const DialogContainer = forwardRef<HTMLDivElement, DialogContainerProps>(
  function DialogContainer(props, ref) {
    const { scrollBehavior, getDialogContainerProps } = useModalContext();

    return (
      <ModalFocusScope>
        <x.div
          ref={ref}
          justifyContent="center"
          alignItems="flex-start"
          position="fixed"
          zIndex="501"
          overflow={scrollBehavior === 'inside' ? 'hidden' : 'auto'}
          display="flex"
          left="0"
          top="0"
          w="100vw"
          h="100vh"
          {...getDialogContainerProps()}
        >
          <DialogContent {...props} />
        </x.div>
      </ModalFocusScope>
    );
  }
);
