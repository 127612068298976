import { MouseEvent, ReactElement, RefObject, useCallback, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';

import { DropdownButton } from 'components/button';
import { Menu, MenuList, MenuTrigger } from 'components/menu';
import { CollectionFilter, useCollectionList } from 'hooks';
import { x } from 'style';

import { SelectMenuItem } from './SelectMenuItem';
import { SelectMenuSearch } from './SelectMenuSearch';

export type RenderTriggerOptions = {
  isActive: boolean;
};

export type SelectMenuProps<T> = {
  source?: T[];

  value?: any | any[];

  label?: string;

  filters?: CollectionFilter<T>[];

  boundaryRef?: RefObject<HTMLElement>;

  labelProp?: string | ((item: T) => string);

  keyProp?: string | ((item: T) => string);

  valueProp?: string | ((item: T) => any);

  multiple?: boolean;

  isSearchable?: boolean;

  selectionMode?: 'inclusive' | 'exclusive';

  allowSelectAll?: boolean;

  allowFocusSelect?: boolean;

  closeOnClick?: boolean | ((e: MouseEvent) => boolean);

  clearSearchOnClose?: boolean;

  renderTrigger?(options: RenderTriggerOptions): ReactElement;

  onClear?(): void;

  onChange?(value: any): void;
};

export function SelectMenu<T>(props: SelectMenuProps<T>) {
  const {
    source,
    label,
    value,
    labelProp,
    keyProp,
    valueProp = (item) => item,
    multiple = false,
    isSearchable = false,
    selectionMode = 'inclusive',
    allowFocusSelect = false,
    allowSelectAll = false,
    filters,
    boundaryRef,
    closeOnClick,
    clearSearchOnClose = true,
    renderTrigger,
    onClear,
    onChange,
  } = props;

  const [searchValue, setSearchValue] = useState<string>('');

  const isActive = value ? (Array.isArray(value) ? value.length > 0 : true) : false;

  const { items, isFiltered } = useCollectionList({
    source,
    filters,
    queryString: searchValue,
    searchKeys: typeof labelProp === 'function' ? [] : labelProp ? [labelProp] : [],
    searchThreshold: 0.2,
  });

  const handleClearButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    onClear?.();
  };

  const handleItemSelect = useCallback(
    (itemValue: any) => {
      let nextValue: any | any[];
      if (multiple) {
        if (!Array.isArray(value)) {
          nextValue = [itemValue];
        } else {
          nextValue = [...value];
          const valueIndex = value.indexOf(itemValue);
          if (valueIndex === -1) {
            nextValue.push(itemValue);
          } else {
            nextValue.splice(valueIndex, 1);
          }
        }
      } else {
        nextValue = itemValue;
      }

      onChange?.(nextValue);
    },
    [multiple, value, onChange]
  );

  const handleAllSelect = useCallback(() => {
    if (selectionMode === 'inclusive') {
      const nextValue = items?.map((item) => getObjectPropValue(item, valueProp));
      onChange?.(nextValue);
    } else {
      onChange?.([]);
    }
  }, [items, selectionMode, valueProp, onChange]);

  const handleFocusSelect = useCallback(
    (value: any) => {
      if (selectionMode === 'inclusive') {
        onChange?.([value]);
      } else {
        const nextValue = items
          ?.map((item) => getObjectPropValue(item, valueProp))
          .filter((v) => v !== value);
        onChange?.(nextValue);
      }
    },
    [selectionMode, items, valueProp, onChange]
  );

  const handleMenuClose = useCallback(() => {
    if (clearSearchOnClose) {
      setSearchValue('');
    }
  }, [clearSearchOnClose]);

  const children = useMemo(() => {
    if (!items.length && isFiltered) {
      return (
        <x.div p={6} textAlign="center" color="gray-500">
          No matching items
        </x.div>
      );
    }

    const children =
      items.map((item, index) => {
        const label = getObjectPropValue(item, labelProp);
        const itemValue = getObjectPropValue(item, valueProp);
        const key = getObjectPropValue(item, keyProp) || itemValue || index;

        let isSelected = false;
        if (multiple) {
          if (selectionMode === 'inclusive') {
            isSelected = Array.isArray(value) ? value.includes(itemValue) : value === itemValue;
          } else {
            isSelected = Array.isArray(value) ? !value.includes(itemValue) : true;
          }
        } else {
          isSelected = value === itemValue;
        }

        return (
          <SelectMenuItem
            isChecked={isSelected}
            closeOnClick={closeOnClick ?? !multiple}
            focusSelect={allowFocusSelect}
            key={key}
            value={itemValue}
            onSelect={handleItemSelect}
            onFocusSelect={handleFocusSelect}
          >
            {label}
          </SelectMenuItem>
        );
      }) || [];

    if (multiple && allowSelectAll) {
      const isSelected = selectionMode === 'inclusive' ? false : !value?.length;

      children.unshift(
        <SelectMenuItem key="all" isChecked={isSelected} onSelect={handleAllSelect}>
          All
        </SelectMenuItem>
      );
    }

    return <MenuList>{children}</MenuList>;
  }, [
    allowSelectAll,
    allowFocusSelect,
    closeOnClick,
    items,
    isFiltered,
    keyProp,
    labelProp,
    multiple,
    selectionMode,
    valueProp,
    value,
    handleAllSelect,
    handleItemSelect,
    handleFocusSelect,
  ]);

  return (
    <MenuTrigger boundaryRef={boundaryRef} onClose={handleMenuClose}>
      {renderTrigger ? (
        renderTrigger({ isActive })
      ) : (
        <DropdownButton
          bg={{ _: isActive ? 'gray-200' : 'transparent', hover: 'gray-200', active: 'gray-300' }}
        >
          {isActive ? (
            <x.span onClick={handleClearButtonClick} p={1}>
              <FiX />
            </x.span>
          ) : null}
          {label}
        </DropdownButton>
      )}

      <Menu>
        {isSearchable ? <SelectMenuSearch value={searchValue} onChange={setSearchValue} /> : null}
        {children}
      </Menu>
    </MenuTrigger>
  );
}

function getObjectPropValue<T>(item: T, prop?: string | ((item: T) => any)): any {
  return prop ? (typeof prop === 'function' ? prop(item) : (item as any)[prop]) : null;
}
