import { forwardRef } from 'react';
import { FiCheck } from 'react-icons/fi';

import { styled } from 'style';

import { MenuItemBase, MenuItemBaseProps } from './MenuItemBase';

const StyledMenuOptionItem = styled(MenuItemBase)({
  gap: '3',
});

const StyledMenuOptionItemIcon = styled.div({
  width: '5',
  flexShrink: 0,
});

export type MenuItemOptionProps = MenuItemBaseProps & {
  type?: 'checkbox' | 'radio';

  value?: any;

  isChecked?: boolean;
};

export const MenuOptionItem = forwardRef<HTMLDivElement, MenuItemOptionProps>(
  function CheckboxMenuItem(props, ref) {
    const { children, type = 'radio', isChecked = false, ...rest } = props;

    return (
      <StyledMenuOptionItem ref={ref} role={`menuitem${type}`} {...rest}>
        <StyledMenuOptionItemIcon>{isChecked ? <FiCheck /> : null}</StyledMenuOptionItemIcon>

        {children}
      </StyledMenuOptionItem>
    );
  }
);
