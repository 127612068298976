import { MouseEvent, ReactNode, forwardRef } from 'react';

import { HTMLProps, styled } from 'style';
import type * as Polymorphic from 'style/polymorphic';

import { useMenuItem } from './useMenu';

const DEFAULT_TAG = 'div';

export const StyledMenuItem = styled(DEFAULT_TAG)({
  backgroundColor: 'white',
  color: 'gray-700',

  alignItems: 'center',
  display: 'flex',
  gap: '3',

  padding: '4 3',

  outline: 'none',

  cursor: 'pointer',

  '&:hover': {
    backgroundColor: 'gray-100',
  },

  '&:focus': {
    backgroundColor: 'gray-200-a80',
  },

  '&[aria-disabled=true]': {
    cursor: 'default',
    color: 'gray-300',
    pointerEvents: 'none',
  },

  '&[href]': {
    textDecoration: 'none',
  },
});

export type MenuItemBaseProps = HTMLProps<'div'> & {
  children?: ReactNode;

  value?: any;

  isDisabled?: boolean;

  /**
   * Whether or not the clicking the item should cause the menu to close.
   */
  closeOnClick?: boolean | ((e: MouseEvent) => boolean);

  onSelect?(value: any): Promise<void> | void;
};

export const MenuItemBase = forwardRef(function MenuItemBase(props, ref) {
  const { children, isDisabled, closeOnClick, ...rest } = props;
  const menuItemProps = useMenuItem(props);

  return (
    <StyledMenuItem ref={ref} {...rest} {...menuItemProps}>
      {children}
    </StyledMenuItem>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, MenuItemBaseProps>;
