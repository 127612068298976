import { AnimatePresence } from 'framer-motion';
import { Children, ReactElement, Ref, cloneElement } from 'react';

import { Portal } from 'components/portal';

import { PopoverContextProvider } from './popoverContext';
import { PopoverPlacement, usePopover } from './usePopover';

export interface PopoverTriggerProps {
  /**
   * The trigger and popover element pair.
   */
  children: [trigger: ReactElement, popover: ReactElement];

  /**
   * Placement of the popover.
   */
  placement?: PopoverPlacement;

  /**
   * Whether the popover is open. Setting this will make the popover a controlled component, meaning
   * the default trigger behavior no longer applies.
   */
  isOpen?: boolean;

  /**
   * Whether the popover is initially open.
   */
  defaultOpen?: boolean;

  /**
   * The trigger method used to control the popover. Defaults to 'hover'.
   */
  trigger?: 'hover' | 'click';

  /**
   * Whether the popover should open on focus.
   */
  openOnFocus?: boolean;
}

export function PopoverTrigger(props: PopoverTriggerProps) {
  const { children } = props;

  const popover = usePopover(props);

  const [triggerElement, popoverElement] = Children.toArray(children) as (ReactElement & {
    ref?: Ref<any>;
  })[];

  return (
    <PopoverContextProvider value={popover}>
      {cloneElement(
        triggerElement,
        popover.getTriggerProps(triggerElement.props, triggerElement.ref)
      )}

      <AnimatePresence>
        {popover.isOpen && (
          <Portal>
            <div {...popover.getContainerProps()}>
              {cloneElement(
                popoverElement,
                popover.getPopoverProps(popoverElement.props, popoverElement.ref)
              )}
            </div>
          </Portal>
        )}
      </AnimatePresence>
    </PopoverContextProvider>
  );
}
