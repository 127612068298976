import { AnimatePresence } from 'framer-motion';
import { ReactNode, forwardRef } from 'react';

import { Portal } from 'components/portal';

import { createContext } from 'utils';

import { UseModalOptions, UseModalReturn, useModal } from './useModal';

export type ScrollBehavior = 'inside' | 'outside';

type ModalOptions = {
  autoFocus?: boolean;
  trapFocus?: boolean;
  allowPinchZoom?: boolean;
  blockScrollOnMount?: boolean;
  removeScrollBar?: boolean;
  scrollBehavior?: ScrollBehavior;
};

type ModalContext = ModalOptions & UseModalReturn & {};

export const [ModalContextProvider, useModalContext] = createContext<ModalContext>({
  strict: true,
  name: 'ModalContext',
});

export type ModalProps = ModalOptions &
  UseModalOptions & {
    children?: ReactNode;
  };

export const Modal = forwardRef<HTMLDivElement, ModalProps>(function Modal(props, ref) {
  const {
    children,
    blockScrollOnMount,
    removeScrollBar,
    allowPinchZoom,
    scrollBehavior = 'outside',
    autoFocus = true,
    trapFocus = true,
  } = props;

  const modal = useModal(props);

  const context = {
    ...modal,
    blockScrollOnMount,
    removeScrollBar,
    allowPinchZoom,
    scrollBehavior,
    autoFocus,
    trapFocus,
  };

  return (
    <ModalContextProvider value={context}>
      <AnimatePresence>{context.isOpen && <Portal>{children}</Portal>}</AnimatePresence>
    </ModalContextProvider>
  );
});
