import { Suspense, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useRecoilState } from 'recoil';

import { currentWorkflowIdState } from 'app';
import { IconButton } from 'components/button';
import { IconPlaceholder, SvgIcon } from 'components/icon';
import { Stack } from 'components/layout';
import { List, ListItem } from 'components/list';
import { SpinnerLoader } from 'components/spinner';
import { useWorkflowsQuery } from 'data/workflows';
import { Application, Workflow } from 'interfaces';
import { x } from 'style';

import { WorkflowResources } from './WorkflowResources';

export type WorkflowsProps = {
  application: Application;
};

export function Workflows(props: WorkflowsProps) {
  const { application } = props;

  const [currentWorkflowId, setCurrentWorkflowId] = useRecoilState(
    currentWorkflowIdState({ applicationId: application.id })
  );

  const { workflows } = useWorkflowsQuery(application.id);
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    () => workflows?.find((x) => x.id === currentWorkflowId) ?? null
  );

  const handleResourceSelect = () => {
    setCurrentWorkflowId(selectedWorkflow!.id);
  };

  return (
    <x.div py={6}>
      {selectedWorkflow ? (
        <>
          <Stack p={2}>
            <IconButton onClick={() => setSelectedWorkflow(null)}>
              <FiArrowLeft />
            </IconButton>

            <x.h2 fontSize="lg" fontWeight="medium">
              {selectedWorkflow.name}
            </x.h2>
          </Stack>

          <Suspense fallback={<SpinnerLoader />}>
            <WorkflowResources
              application={application!}
              workflowId={selectedWorkflow.id}
              onSelect={handleResourceSelect}
            />
          </Suspense>
        </>
      ) : (
        <List hideDivider>
          {workflows?.map((item) => (
            <ListItem key={item.id}>
              {item.iconUri ? (
                <SvgIcon size={8} dataUri={item.iconUri} />
              ) : (
                <IconPlaceholder size={8} />
              )}

              <x.span role="button" fontSize="lg" onClick={() => setSelectedWorkflow(item)}>
                {item.name}
              </x.span>
            </ListItem>
          ))}
        </List>
      )}
    </x.div>
  );
}
