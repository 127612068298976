import { ThemeProvider as XStyledThemeProvider } from '@xstyled/emotion';

import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

import { usePreferenceState } from 'data/preferences';

import { themeSelector } from './ThemeState';

export interface ThemeProviderProps {
  children?: ReactNode;
}

export function ThemeProvider(props: ThemeProviderProps) {
  const [themeMode] = usePreferenceState({ key: 'themeMode', defaultValue: 'organization' });
  const theme = useRecoilValue(themeSelector({ themeMode }));

  return <XStyledThemeProvider theme={theme} {...props} />;
}

export { XStyledThemeProvider };
