import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface CardTitleProps extends StyledProps {}

export const CardTitle = forwardRef<HTMLHeadingElement, StyledProps>(function CardTitle(
  props,
  ref
) {
  return <x.h3 ref={ref} fontSize="xl" fontWeight="medium" {...props} />;
});
