import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import { IconButton } from 'components/button';
import { Stack } from 'components/layout';
import { Tooltip } from 'components/tooltip';
import { x } from 'style';

export interface PaginatorProps {
  showPageNumbers?: boolean;
  page?: number;
  pageCount?: number;
  onPageChange?: (page: number) => void;
}

export function Paginator(props: PaginatorProps) {
  const { page = 1, pageCount = 1, showPageNumbers = true, onPageChange } = props;

  const isPrevDisabled = page <= 1;
  const isNextDisabled = page >= pageCount;

  return (
    <Stack>
      <Tooltip label="Previous page">
        <IconButton
          isDisabled={isPrevDisabled}
          aria-label="Previous page"
          onClick={() => onPageChange?.(page - 1)}
        >
          <FiChevronLeft />
        </IconButton>
      </Tooltip>

      {showPageNumbers ? (
        <x.span fontSize="sm" color="gray-600">
          {page} of {pageCount}
        </x.span>
      ) : null}

      <Tooltip label="Next page">
        <IconButton
          isDisabled={isNextDisabled}
          aria-label="Next page"
          onClick={() => onPageChange?.(page + 1)}
        >
          <FiChevronRight />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
