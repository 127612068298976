import { Children, ReactElement, forwardRef, isValidElement } from 'react';

import { x } from 'style';

import { Breadcrumb } from './Breadcrumb';

export interface BreadcrumbsProps {
  children?: ReactElement | ReactElement[];
}

export const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbsProps>(function BreadcrumbsWithRef(
  props,
  ref
) {
  const { children } = props;

  const childArray = Children.toArray(children).filter((child) =>
    isValidElement(child)
  ) as ReactElement[];
  const lastIndex = childArray.length - 1;

  const breadcrumbItems = childArray.map((child, index) => {
    const isActive = lastIndex === index;
    const key = child.key || index;

    return <Breadcrumb {...child.props} isActive={isActive} key={key} />;
  });

  return (
    <x.nav ref={ref} display="flex" alignItems="center" gap={3} mb={6}>
      {breadcrumbItems}
    </x.nav>
  );
});
