import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface CardProps extends StyledProps {}

export const Card = forwardRef<HTMLDivElement, CardProps>(function Card(props, ref) {
  return (
    <x.div
      ref={ref}
      borderRadius="default"
      boxShadow="default"
      bg="white"
      mb={{ _: 9, last: 0 }}
      {...props}
    />
  );
});
