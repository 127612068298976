import {
  ApplicationId,
  CreateDataViewPayload,
  DataView,
  DataViewId,
  DataViewStpDetail,
  ScenarioId,
  UpdateDataViewPayload,
} from 'interfaces';

import { api } from './api';

/**
 * Fetches all data views belonging to the specified application.
 * @param applicationId the application ID.
 */
export function getDataViews(applicationId: ApplicationId) {
  return api.get<DataView[]>(`/applications/${applicationId}/data-views`);
}

/**
 * Creates a new data view for the specified application.
 * @param applicationId The application ID.
 * @param payload The request payload used to create the data view.
 */
export function createDataView(applicationId: ApplicationId, payload: CreateDataViewPayload) {
  return api.post<DataView>(`/applications/${applicationId}/data-views`, payload);
}

/**
 * Deletes an existing data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 */
export function deleteDataView(applicationId: ApplicationId, dataViewId: DataViewId) {
  return api.delete(`/applications/${applicationId}/data-views/${dataViewId}`);
}

/**
 * Fetches a single data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 */
export function getDataView(applicationId: ApplicationId, dataViewId: DataViewId) {
  return api.get<DataView>(`/applications/${applicationId}/data-views/${dataViewId}`);
}

/**
 * Fetches the STP details for a data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 * @param scenarioId The scenario ID.
 */
export function getDataViewStpDetails(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  scenarioId?: ScenarioId | null
) {
  const params: { [key: string]: string } = {};
  if (scenarioId) {
    params.scenarioId = scenarioId.toString();
  }

  return api.get<DataViewStpDetail[]>(
    `/applications/${applicationId}/data-views/${dataViewId}/stp-details`,
    {
      params,
    }
  );
}

/**
 * Updates an existing data view belonging to the specified application.
 * @param applicationId The application ID.
 * @param dataViewId the data view ID.
 * @param payload The request payload used to update the data view.
 */
export function updateDataView(
  applicationId: ApplicationId,
  dataViewId: DataViewId,
  payload: UpdateDataViewPayload
) {
  return api.put<DataView>(`/applications/${applicationId}/data-views/${dataViewId}`, payload);
}

/**
 * Adds or removes the data view as a favorite for the current user.
 * @param applicationId The application ID.
 * @param dataViewId The data view ID.
 */
export function setDataViewFavorited(
  applicationId: ApplicationId,
  dataViewId: ScenarioId,
  isFavorite: boolean
) {
  return api.patch<void>(`/applications/${applicationId}/data-views/${dataViewId}/favorite`, {
    isFavorite,
  });
}
