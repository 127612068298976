import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface PopoverSubtitleProps extends StyledProps {}

export const PopoverSubtitle = forwardRef<HTMLHeadingElement, PopoverSubtitleProps>(
  function PopoverSubtitle(props, ref) {
    return <x.div ref={ref} fontSize="sm" fontWeight="thin" color="gray-500" mt={1} {...props} />;
  }
);
