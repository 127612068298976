import { AnimatePresence } from 'framer-motion';
import { Children, ReactElement, Ref, RefObject, cloneElement } from 'react';
// import FocusLock from 'react-focus-lock';

import { Portal } from 'components/portal';

import { MenuContextProvider } from './MenuContext';
import { MenuPlacement, UseMenuOptions, useMenu } from './useMenu';

export type MenuTriggerProps = UseMenuOptions & {
  children: [trigger: ReactElement<HTMLButtonElement>, menu: ReactElement];
  placement?: MenuPlacement;
  boundaryRef?: RefObject<HTMLElement>;
  onClose?(): void;
};

export const MenuTrigger = (props: MenuTriggerProps) => {
  const { children } = props;

  const menu = useMenu(props);

  const [triggerElement, menuElement] = Children.toArray(children) as (ReactElement & {
    ref?: Ref<any>;
  })[];

  return (
    <MenuContextProvider value={menu}>
      {cloneElement(triggerElement, menu.getTriggerProps(triggerElement.props, triggerElement.ref))}

      <AnimatePresence>
        {menu.isOpen ? (
          menu.renderInPortal ? (
            <Portal>
              <div {...menu.getContainerProps()}>
                {cloneElement(
                  menuElement,
                  menu.getPopoverProps(menuElement.props, menuElement.ref)
                )}
              </div>
            </Portal>
          ) : (
            <div {...menu.getContainerProps()}>
              {cloneElement(menuElement, menu.getPopoverProps(menuElement.props, menuElement.ref))}
            </div>
          )
        ) : null}
      </AnimatePresence>
    </MenuContextProvider>
  );
};
