import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { forwardRef, useEffect, useState } from 'react';

import { TransitionEasings, Variants, withDelay } from './utils';

export type CollapseOptions = {
  animateOpacity?: boolean;
  startingHeight?: number | string;
  endingHeight?: number | string;
};

export type CollapseProps = HTMLMotionProps<'div'> &
  CollapseOptions & {
    in?: boolean;
    unmountOnExit?: boolean;
  };

const defaultTransitions = {
  exit: {
    height: { duration: 0.2, ease: TransitionEasings.ease },
    opacity: { duration: 0.3, ease: TransitionEasings.ease },
  },
  enter: {
    height: { duration: 0.3, ease: TransitionEasings.ease },
    opacity: { duration: 0.4, ease: TransitionEasings.ease },
  },
};

const variants: Variants<CollapseOptions> = {
  enter: ({ animateOpacity, endingHeight, transition, transitionEnd, delay }) => ({
    ...(animateOpacity && { opacity: 1 }),
    height: endingHeight,
    transition: transition?.enter ?? withDelay.exit(defaultTransitions.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ animateOpacity, startingHeight, transition, transitionEnd, delay }) => ({
    ...(animateOpacity && {
      opacity: startingHeight && parseInt(startingHeight.toString(), 10) > 0 ? 1 : 0,
    }),
    overflow: 'hidden',
    height: startingHeight,
    transition: transition?.exit ?? withDelay.exit(defaultTransitions.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
};

export const Collapse = forwardRef<HTMLDivElement, CollapseProps>(function Collapse(props, ref) {
  const {
    in: isOpen,
    unmountOnExit,
    animateOpacity = true,
    startingHeight = 0,
    endingHeight = 'auto',
    transition,
    style,
    ...rest
  } = props;

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setMounted(true);
    });
    return () => clearTimeout(timeout);
  }, []);

  const custom = {
    animateOpacity,
    startingHeight,
    endingHeight,
    transition: !mounted ? { enter: { duration: 0 } } : transition,
    transitionEnd: {
      enter: {
        overflow: 'initial',
      },
      exit: unmountOnExit
        ? undefined
        : {
            display: startingHeight ? 'block' : 'none',
          },
    },
  };

  const show = unmountOnExit ? isOpen : true;
  const animate = isOpen || unmountOnExit ? 'enter' : 'exit';

  return (
    <AnimatePresence custom={custom} initial={false}>
      {show && (
        <motion.div
          ref={ref}
          {...rest}
          style={{
            overflow: 'hidden',
            display: 'block',
            ...style,
          }}
          variants={variants}
          animate={animate}
          initial={unmountOnExit ? 'exit' : false}
          custom={custom}
          exit="exit"
        />
      )}
    </AnimatePresence>
  );
});
