import {
  aliasColor,
  generateHexAlphaVariants,
  th,
  defaultTheme as xstyledTheme,
} from '@xstyled/emotion';

import deloitteLogo from './deloitteLogo.png';

const colors = {
  'blue-100': '#BBDEFB',
  'blue-200': '#90CAF9',
  'blue-300': '#64B5F6',
  'blue-400': '#42A5F5',
  'blue-500': '#2196F3',
  'blue-600': '#1E88E5',
  'blue-700': '#1976D2',
  'blue-800': '#1565C0',
  'blue-900': '#0D47A1',

  'red-50': '#fef2f2ff',
  'red-100': '#fee2e2ff',
  'red-200': '#fecacaff',
  'red-300': '#fca5a5ff',
  'red-400': '#f87171ff',
  'red-500': '#ef4444ff',
  'red-600': '#dc2626ff',
  'red-700': '#b91c1cff',
  'red-800': '#991b1bff',
  'red-900': '#7f1d1dff',

  'orange-50': '#fff7edff',
  'orange-100': '#ffedd5ff',
  'orange-200': '#fed7aaff',
  'orange-300': '#fdba74ff',
  'orange-400': '#fb923cff',
  'orange-500': '#f97316ff',
  'orange-600': '#ea580cff',
  'orange-700': '#c2410cff',
  'orange-800': '#9a3412ff',
  'orange-900': '#7c2d12ff',

  'green-50': '#f0fdf4ff',
  'green-100': '#dcfce7ff',
  'green-200': '#bbf7d0ff',
  'green-300': '#86efacff',
  'green-400': '#4ade80ff',
  'green-500': '#22c55eff',
  'green-600': '#16a34aff',
  'green-700': '#15803dff',
  'green-800': '#166534ff',
  'green-900': '#14532dff',

  'gray-50': '#F8FAFC',
  'gray-100': '#F1F5F9',
  'gray-200': '#E2E8F0',
  'gray-300': '#CBD5E1',
  'gray-400': '#94A3B8',
  'gray-500': '#64748B',
  'gray-600': '#475569',
  'gray-700': '#334155',
  'gray-800': '#1E293B',
  'gray-900': '#0F172A',
};

export const defaultTheme = {
  ...xstyledTheme,
  logo: 'default',
  colors: {
    ...generateHexAlphaVariants(colors),
    ...aliasColor('primary', 'blue'),
    ...aliasColor('danger', 'red'),
    ...aliasColor('warning', 'orange'),
    background: 'hsl(218, 31%, 95%)',
    text: '#334155',
    components: {
      tooltip: {
        bg: 'rgba(0, 26, 66, .75)',
        text: 'white',
      },
      navbar: {
        background: th.color('primary-700') as any,
        text: th.color('white', 'white') as any,
      },
    },
  },
  screens: {
    _: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
  gridTemplateColumns: {
    ...xstyledTheme.gridTemplateColumns,
    'auto-fill': 'repeat(auto-fill, minmax(0, 1fr))',
  },
};

export type AppTheme = typeof defaultTheme;

export function createTheme(palette: any, fn: (base: AppTheme) => AppTheme): AppTheme {
  return fn({
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      ...palette,
    },
  });
}

export const altTheme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    'primary-500': '#5AA392',
    'primary-600': '#459591',
    'primary-700': '#3B7884',
    'primary-800': '#325A72',
    'primary-900': '#293F60',
  },
};

export const deloitteTheme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    'primary-50': '#f1f7e6',
    'primary-100': '#dcebc0',
    'primary-200': '#c4dd98',
    'primary-300': '#acd06d',
    'primary-400': '#99c64b',
    'primary-500': '#86bc25',
    'primary-600': '#78ac1d',
    'primary-700': '#639911',
    'primary-800': '#4e8505',
    'primary-900': '#266400',
    components: {
      ...defaultTheme.colors.components,
      navbar: {
        logo: deloitteLogo,
        backgroundColor: 'black',
        color: 'white',
      },
    },
  },
};

export const themeMap: Record<string, any> = {
  primary: defaultTheme,
  alt: altTheme,
  deloitte: deloitteTheme,
};
