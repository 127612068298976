import { useModalContext } from 'components/modal';
import { StyledProps, x } from 'style';

export type DrawerBodyProps = StyledProps & {};

export function DrawerBody(props: DrawerBodyProps) {
  const { bodyId } = useModalContext();

  return <x.div id={bodyId} p={6} overflowX="auto" {...props} />;
}
