import { motion } from 'framer-motion';
import { ReactNode, forwardRef, useRef } from 'react';

import { ScaleFade } from 'components/transitions';
import { popperCSSVars, useMergedRefs } from 'hooks';
import { styled } from 'style';

import { useMenuContext } from './MenuContext';

export const StyledMenu = styled.div({
  borderRadius: 'md',
  background: 'white',
  boxShadow: 'xs, lg',

  transformOrigin: `${popperCSSVars.transformOrigin.varRef}`,

  outline: 'none',
  flexDirection: 'column',
  overflow: 'hidden',
  display: 'flex',

  maxHeight: `inherit`,
  width: '72',
});

const AnimatedMenu = motion(StyledMenu);

export type MenuProps = {
  children?: ReactNode;
};

export const Menu = forwardRef<HTMLDivElement, MenuProps>(function Menu(props, forwardedRef) {
  const { children, ...rest } = props;

  const ref = useRef<HTMLDivElement>(null);
  const handleRef = useMergedRefs(ref, forwardedRef);

  const { isOpen } = useMenuContext();

  return (
    <ScaleFade in={isOpen}>
      {(motionProps: any) => (
        <AnimatedMenu ref={handleRef} {...motionProps} role="menu" tabIndex={-1} {...rest}>
          {children}
        </AnimatedMenu>
      )}
    </ScaleFade>
  );
});
