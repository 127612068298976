import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface PopoverContentProps extends StyledProps {}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  function PopoverContent(props, forwardedRef) {
    return <x.div ref={forwardedRef} px={4} py={3} {...props} />;
  }
);
