import { useCallback, useRef, useState } from 'react';

import { useUnmountEffect } from './useUnmountEffect';

/**
 * Returns a function that forces a rerender.
 */
export function useForceUpdate() {
  const [count, setCount] = useState(0);
  const unmountRef = useRef(false);

  useUnmountEffect(() => {
    unmountRef.current = true;
  });

  return useCallback(() => {
    if (!unmountRef.current) {
      setCount(count + 1);
    }
  }, [count]);
}
