import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { ResourceType } from 'interfaces';

const RESOURCE_TYPE_PATHS: Record<ResourceType, string> = {
  dataView: 'scenarios/editor?dataViewId',
  report: 'reports/viewer?reportId',
  mimicBoard: 'mimic-boards/viewer?mimicBoardId',
};

type ResourceLinkProps = Omit<LinkProps, 'to'> & {
  applicationPath: string;
  resourceType: ResourceType;
  resourceId: number;
};

export const ResourceLink: FC<ResourceLinkProps> = (props) => {
  const { resourceId, resourceType, applicationPath, ...rest } = props;

  const url: string = `/${applicationPath}/${RESOURCE_TYPE_PATHS[resourceType]}=${resourceId}`;

  return <Link to={url} {...rest} />;
};
