import { Suspense, useEffect, useState } from 'react';

import { useCurrentApplication } from 'app';
import { Drawer, DrawerBody, DrawerHeader, DrawerTitle } from 'components/drawer';
import { SpinnerLoader } from 'components/spinner';

import { AppLinks } from './AppLinks';
import { GlobalLinks } from './GlobalLinks';

export type QuickNavigationProps = {
  isOpen?: boolean;
  onClose?(): void;
};

export function QuickNavigation(props: QuickNavigationProps) {
  const { isOpen, onClose } = props;

  const application = useCurrentApplication(false);

  const [showAll, setShowAll] = useState(false);

  const handleNavigate = () => {
    onClose?.();
  };

  useEffect(() => {
    if (!isOpen) {
      setShowAll(false);
    }
  }, [isOpen]);

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader>
        <DrawerTitle>Quick navigation</DrawerTitle>
      </DrawerHeader>

      <DrawerBody>
        <Suspense fallback={<SpinnerLoader />}>
          {application && !showAll ? (
            <AppLinks application={application} onShowAll={() => setShowAll(true)} />
          ) : (
            <GlobalLinks onNavigate={handleNavigate} />
          )}
        </Suspense>
      </DrawerBody>
    </Drawer>
  );
}
