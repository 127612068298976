import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export const CardActions = forwardRef<HTMLDivElement, StyledProps>(function CardActions(
  props,
  ref
) {
  return (
    <x.div
      ref={ref}
      borderRadius="0 0 default default"
      justifyContent="flex-end"
      display="flex"
      gap={6}
      bg="gray-100"
      p={6}
      {...props}
    />
  );
});
