import { forwardRef } from 'react';

import { HTMLProps, SystemProps, x } from 'style';

export type InputProps = HTMLProps<'input'> & SystemProps;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  return (
    <x.input
      ref={ref}
      bg="white"
      borderRadius="md"
      lineHeight={6}
      boxShadow="sm"
      display="block"
      outline="none"
      border="1px solid"
      color={{ _: 'gray-600', placeholder: 'gray-400' }}
      borderColor={{ _: 'gray-300', hover: 'gray-400', focus: 'primary-500' }}
      ringColor={{ focus: 'primary-500' }}
      ring={{ _: 0, focus: 1 }}
      px={3}
      py={2}
      w="full"
      {...props}
    />
  );
});
