import { ReactNode, forwardRef } from 'react';

import { x } from 'style';

import { useSideLayoutContext } from './SideLayoutContext';

export type SideLayoutContentProps = {
  children?: ReactNode;
};

export const SideLayoutContent = forwardRef<HTMLDivElement, SideLayoutContentProps>(
  function SideLayoutContent(props, ref) {
    const { margins } = useSideLayoutContext();

    return (
      <x.div
        ref={ref}
        position="relative"
        overflow="auto"
        display="block"
        h="100%"
        style={{
          translate: 'transform3d(0, 0, 0)',
          marginLeft: margins.left,
          marginRight: margins.right,
        }}
      >
        {props.children}
      </x.div>
    );
  }
);
