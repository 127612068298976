import { forwardRef } from 'react';

import { TruncateText } from 'components/typography';
import type * as Polymorphic from 'style/polymorphic';

import { MenuItemBase, MenuItemBaseProps } from './MenuItemBase';

const DEFAULT_TAG = 'div';

export type MenuItemProps = MenuItemBaseProps;

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(function MenuItem(props, ref) {
  const { children, ...rest } = props;

  return (
    <MenuItemBase ref={ref} {...rest}>
      <TruncateText flex="1">{children}</TruncateText>
    </MenuItemBase>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, MenuItemProps>;
