import { RefObject } from 'react';

import { DropdownButton } from 'components/button';
import { Menu, MenuOptionGroup, MenuOptionItem, MenuTrigger } from 'components/menu';

import { useEntityListContext } from './EntityListContext';

export interface EntityListSortProps {
  containerRef?: RefObject<HTMLElement>;
}

export function EntityListSort(props: EntityListSortProps) {
  const { containerRef } = props;
  const { sorting, query, setSorting } = useEntityListContext();

  const updateSorting = (prop: string) => {
    setSorting(prop, query.sortProp === prop && query.sortDir === 'asc' ? 'desc' : 'asc');
  };

  if (!sorting) {
    return null;
  }

  return (
    <MenuTrigger boundaryRef={containerRef}>
      <DropdownButton bg={{ _: 'transparent', hover: 'gray-200', active: 'gray-300' }}>
        Sort
      </DropdownButton>

      <Menu>
        <MenuOptionGroup value={query.sortProp} onChange={updateSorting}>
          {sorting.map((item) => (
            <MenuOptionItem key={String(item.key)} value={item.key}>
              {item.label || item.key}
            </MenuOptionItem>
          ))}
        </MenuOptionGroup>
      </Menu>
    </MenuTrigger>
  );
}
