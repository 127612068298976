import { KeyboardEvent, MouseEvent, useCallback, useRef } from 'react';

import { useIds } from 'hooks';
import { mergeProps, mergeRefs } from 'utils';

export type UseModalOptions = {
  /**
   * Whether or not the modal is open.
   */
  isOpen?: boolean;

  /**
   * The modal ID.
   */
  id?: string;

  /**
   * Whether or not to close the modal when the overlay is clicked.
   */
  closeOnOverlayClick?: boolean;

  /**
   * Whether or not to close the modal when the ESC key is clicked.
   */
  closeOnEsc?: boolean;

  /**
   * The callback invoked when the modal is closed.
   */
  onClose?(): void;

  /**
   * The callback invoked when the ESC key is pressed.
   */
  onEsc?(): void;

  /**
   * The callback invoked when the overlay is clicked.
   */
  onOverlayClick?(): void;
};

export function useModal(options: UseModalOptions) {
  const {
    isOpen,
    id,
    closeOnOverlayClick = true,
    closeOnEsc = true,
    onClose,
    onEsc,
    onOverlayClick: onOverlayClickProp,
  } = options;

  const dialogRef = useRef<HTMLElement>(null);
  const overlayRef = useRef<HTMLElement>(null);
  const mouseDownTarget = useRef<EventTarget | null>(null);

  const [dialogId, headerId, bodyId] = useIds(id, 'modal', 'modal-header', 'modal-body');

  const onClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation();

        if (closeOnEsc) {
          onClose?.();
        }

        onEsc?.();
      }
    },
    [closeOnEsc, onClose, onEsc]
  );

  const onMouseDown = useCallback((e: MouseEvent) => {
    mouseDownTarget.current = e.target;
  }, []);

  const onOverlayClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      if (mouseDownTarget.current !== e.target) {
        return;
      }

      if (closeOnOverlayClick) {
        onClose?.();
      }

      onOverlayClickProp?.();
    },
    [closeOnOverlayClick, onClose, onOverlayClickProp]
  );

  const getDialogProps = useCallback(
    (props: any = {}, ref = null) =>
      mergeProps(props, {
        ref: mergeRefs(ref, dialogRef),
        role: 'dialog',
        id: dialogId,
        tabIndex: -1,
        'aria-modal': true,
        'aria-labelledby': headerId,
        'aria-describedby': bodyId,
        onClick,
      }),
    [bodyId, dialogId, headerId, onClick]
  );

  const getDialogContainerProps = useCallback(
    (props: any = {}, ref = null) =>
      mergeProps(props, {
        ref: mergeRefs(ref, overlayRef),
        onClick: onOverlayClick,
        onKeyDown,
        onMouseDown,
      }),
    [onKeyDown, onMouseDown, onOverlayClick]
  );

  return {
    isOpen,
    bodyId,
    headerId,
    dialogId,
    dialogRef,
    overlayRef,
    onClose,
    getDialogProps,
    getDialogContainerProps,
  };
}

export type UseModalReturn = ReturnType<typeof useModal>;
