import { forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { IconButton, IconButtonProps } from './IconButton';

export interface IconButtonLinkProps extends IconButtonProps, Pick<LinkProps, 'to'> {}

/**
 * Component used to render icon button links.
 */
export const IconButtonLink = forwardRef<HTMLAnchorElement, IconButtonLinkProps>(function (
  props,
  ref
) {
  return <IconButton as={Link} {...props} />;
});
