import { useMemo } from 'react';

import { Switch } from 'components/switch';

import { useEntityListContext } from './EntityListContext';

export type EntityListToggleFilterProps = {
  filterKey: string;
  label?: string;
};

export function EntityListToggleFilter(props: EntityListToggleFilterProps) {
  const { filterKey, label = 'Toggle' } = props;

  const { getFilter, setFilter } = useEntityListContext();

  const isChecked = useMemo(() => {
    if (filterKey.startsWith('is:')) {
      const filterValue = filterKey.split('is:')[1]?.toLowerCase();
      const values = getFilter('is');
      for (const v of values) {
        if (v.toLowerCase() === filterValue) {
          return true;
        }
      }
    }

    return false;
  }, [filterKey, getFilter]);

  const handleChange = (value: boolean) => {
    if (value === true) {
      const filterValue = filterKey.split('is:')[1]?.toLowerCase();
      setFilter('is', filterValue);
    } else {
      setFilter('is', null);
    }
  };

  return (
    <Switch isChecked={isChecked} onChange={handleChange}>
      {label}
    </Switch>
  );
}
