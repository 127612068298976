import { motion } from 'framer-motion';
import { ReactNode, Suspense } from 'react';

import { useModalContext } from 'components/modal';
import { SpinnerLoader } from 'components/spinner';
import { TransitionEasings, Variants, withDelay } from 'components/transitions';
import { x } from 'style';

import { DialogSize } from './types';

const DIALOG_SIZES: Record<Partial<DialogSize>, any> = {
  lg: '42rem',
  md: '34rem',
  sm: '28rem',
};

const defaultTransitions = {
  enter: {
    duration: 0.2,
    ease: TransitionEasings.easeOut,
  },
  exit: {
    duration: 0.1,
    ease: TransitionEasings.easeIn,
  },
};

const variants: Variants = {
  initial: ({ delay, transition, transitionEnd }) => ({
    opacity: 0,
    scale: 0.9,
    transition: transition?.exit ?? withDelay.exit(defaultTransitions.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
  enter: ({ delay, transition, transitionEnd }) => ({
    opacity: 1,
    scale: 1,
    transition: transition?.enter ?? withDelay.enter(defaultTransitions.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ delay, transition, transitionEnd }) => ({
    opacity: 0,
    y: 8,
    transition: transition?.exit ?? withDelay.exit(defaultTransitions.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
};

const motionConfig = {
  initial: 'initial',
  animate: 'enter',
  exit: 'exit',
  variants,
};

const Motion = motion(x.div);

export type DialogContentProps = {
  children?: ReactNode;

  size?: DialogSize;
};

export function DialogContent(props: DialogContentProps) {
  const { children, size = 'md' } = props;

  const { scrollBehavior, getDialogProps } = useModalContext();

  const width = typeof size === 'number' ? size : DIALOG_SIZES[size];

  return (
    <Motion
      flexDirection="column"
      borderRadius="default"
      boxShadow="lg"
      position="relative"
      display="flex"
      maxH={scrollBehavior === 'inside' ? 'calc(100vh - 5rem)' : undefined}
      maxW={width}
      bg="white"
      my={24}
      w="100%"
      {...getDialogProps({ ...motionConfig, custom: {} })}
    >
      <Suspense fallback={<SpinnerLoader />}>{children}</Suspense>
    </Motion>
  );
}
