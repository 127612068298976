import { x } from 'style';

import { Spinner } from './Spinner';

export type SpinnerLoaderProps = {
  size?: number;
};

export function SpinnerLoader(props: SpinnerLoaderProps) {
  const { size = 8 } = props;

  return (
    <x.div justifyContent="center" alignItems="center" display="flex" w="100%" h="100%" py={6}>
      <Spinner isIndeterminate size={size} color="primary-700" />
    </x.div>
  );
}
