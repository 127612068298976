import { cloneElement } from 'react';

import { x } from 'style';

export type InlineSvgIconProps = {
  svg: any;
  size?: string | number;
};

export type DataUriSvgIconProps = {
  dataUri: string;
  size?: string | number;
  altText?: string;
};

export type SvgIconProps = InlineSvgIconProps | DataUriSvgIconProps;

export function SvgIcon(props: SvgIconProps) {
  const { size = 24 } = props;

  if (isInlineSvgProps(props)) {
    return cloneElement(props.svg, {
      fill: 'currentColor',
      width: size,
      height: size,
    });
  } else {
    return <x.img src={props.dataUri} w={size} h={size} alt={props.altText} />;
  }
}

function isInlineSvgProps(value: SvgIconProps): value is InlineSvgIconProps {
  return 'svg' in value;
}
