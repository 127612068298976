import { useMutation, useQuery, useQueryClient } from 'react-query';

import * as organizations from 'api/organizations';
import {
  ApplicationId,
  CreateOrganizationGroupPayload,
  CreateOrganizationPayload,
  Organization,
  OrganizationGroupId,
  OrganizationId,
  RenameOrganizationPayload,
  SetOrganizationThemePayload,
  UpdateOrganizationGroupPayload,
} from 'interfaces';

import { useApplicationGroupsQuery } from './applications';

/**
 * Hook that queries the organizations.
 * @returns The organizations.
 */
export function useOrganizationsQuery() {
  const { data } = useQuery('organizations', () => organizations.getOrganizations());

  return {
    organizations: data!,
  };
}

export function useGetOrganization(organizationIdOrPath: string | OrganizationId) {
  const { data } = useQuery([`organizations`, organizationIdOrPath], async () =>
    organizations.getOrganization(organizationIdOrPath)
  );

  return {
    organization: data,
  };
}

export function useOrganizationRolesQuery() {
  const { data } = useQuery([`organization-roles`], () => organizations.getOrganizationRoles());

  return {
    organizationRoles: data!,
  };
}

export function useOrganizationGroupsQuery(organizationId: OrganizationId) {
  const { data } = useQuery([`organizations`, organizationId, 'groups'], () =>
    organizations.getOrganizationGroups(organizationId)
  );

  return {
    organizationGroups: data!,
  };
}

export function useGetOrganizationGroup(
  organizationId: OrganizationId,
  organizationGroupId: OrganizationGroupId
) {
  const { data } = useQuery([`organizations`, organizationId, 'groups', organizationGroupId], () =>
    organizations.getOrganizationGroup(organizationId, organizationGroupId)
  );

  return {
    organizationGroup: data,
  };
}

export function useCreateOrganizationMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateOrganizationPayload) => organizations.createOrganization(payload),
    {
      onSuccess: (organization: Organization) => {
        queryClient.setQueryData<Organization[]>('organizations', (data) => {
          return [...(data || []), organization];
        });

        queryClient.invalidateQueries('organizations');
      },
    }
  );
}

export function useCreateOrganizationGroup(organizationId: OrganizationId) {
  const mutation = useMutation(
    (payload: CreateOrganizationGroupPayload) =>
      organizations.createOrganizationGroup(organizationId, payload),
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

export function useDeleteOrganizationGroupMutation(organizationId: OrganizationId) {
  const mutation = useMutation(
    (organizationGroupId: OrganizationGroupId) =>
      organizations.deleteOrganizationGroup(organizationId, organizationGroupId),
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

export function useUpdateOrganizationGroup(
  organizationId: OrganizationId,
  organizationGroupId: OrganizationGroupId
) {
  const mutation = useMutation(
    (payload: UpdateOrganizationGroupPayload) =>
      organizations.updateOrganizationGroup(organizationId, organizationGroupId, payload),
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

export function useRenameOrganization(organizationId: OrganizationId) {
  const mutation = useMutation(
    (payload: RenameOrganizationPayload) => {
      return organizations.renameOrganization(organizationId, payload);
    },
    {
      onSuccess: () => {},
    }
  );

  return mutation;
}

/**
 * Hook that returns organization groups that are assigned to an application.
 * @param organizationId The organization ID.
 * @param applicationId The application ID.
 * @returns The `OrganizationGroup` items that are assigned to an application.
 */
export function useAssignableApplicationGroups(
  organizationId: OrganizationId,
  applicationId: ApplicationId
) {
  const { applicationGroups } = useApplicationGroupsQuery(applicationId);
  const { organizationGroups } = useOrganizationGroupsQuery(organizationId);

  const assignableGroups = organizationGroups.filter(
    (x) => x.requiresResourceAssignment && applicationGroups.includes(x.id)
  );

  return {
    assignableGroups,
  };
}

/**
 * Hook that queries the organization theme.
 * @param organizationId The organization ID.
 * @returns The organization theme data.
 */
export function useOrganizationThemeQuery(organizationId: OrganizationId) {
  const { data } = useQuery(['organizations', organizationId, 'theme'], () =>
    organizations.getOrganizationTheme(organizationId)
  );

  return {
    organizationTheme: data!,
  };
}

/**
 * Hook that returns a mutation used to set an organization's theme.
 * @param organizationId The organization ID.
 * @returns The `UseMutationResult`.
 */
export function useSetOrganizationThemeMutation(organizationId: OrganizationId) {
  return useMutation(
    (payload: SetOrganizationThemePayload) =>
      organizations.setOrganizationTheme(organizationId, payload),
    {}
  );
}
