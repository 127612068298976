import { ReactChild, ReactNode } from 'react';

import { x } from 'style';

export type FieldsetProps = {
  children?: ReactNode;

  /**
   * The fieldset label.
   */
  label?: ReactChild;
};

export function Fieldset(props: FieldsetProps) {
  const { children, label } = props;

  return (
    <x.fieldset p={0} m={{ _: 0 }}>
      {label && (
        <x.legend mb={6} fontSize="lg" fontWeight="normal">
          {label}
        </x.legend>
      )}

      {children}
    </x.fieldset>
  );
}
