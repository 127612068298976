import { useCallback, useMemo, useState } from 'react';

export type UseSelectionStateOptions<T> = {
  initialValue?: Iterable<T> | T;
  multiple?: boolean;
  onChange?(value: T | T[]): void;
};

export function useSelectionState<T = unknown>(opts: UseSelectionStateOptions<T>) {
  const { initialValue: initialSelection = [], multiple = true, onChange: onChangeProp } = opts;

  const [selection, setSelectionState] = useState(() => {
    if (initialSelection === null || initialSelection === undefined) {
      return new Set<T>();
    } else if (
      typeof initialSelection === 'string' ||
      typeof initialSelection === 'number' ||
      typeof initialSelection === 'boolean'
    ) {
      return new Set<T>([initialSelection as T]);
    } else if (typeof (initialSelection as any)[Symbol.iterator] === 'function') {
      return new Set<T>(initialSelection as Iterable<T>);
    }

    return new Set<T>();
  });

  const isEmpty = selection.size === 0;

  const isSelected = useCallback((value: T) => selection.has(value), [selection]);

  const setSelection = useCallback(
    (value: Set<T>) => {
      if (multiple) {
        onChangeProp?.(Array.from(value));
      } else {
        onChangeProp?.(Array.from(value)[0]);
      }

      setSelectionState(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeProp]
  );

  const select = useCallback(
    (...values: T[]) => {
      const nextValue = new Set(multiple ? selection : []);
      values.forEach((value) => nextValue.add(value));
      setSelection(nextValue);
      return nextValue;
    },
    [multiple, selection, setSelection]
  );

  const deselect = useCallback(
    (...values: T[]) => {
      const nextValue = new Set(selection);
      values.forEach((value) => nextValue.delete(value));
      setSelection(nextValue);
      return nextValue;
    },
    [selection, setSelection]
  );

  const toggle = useCallback(
    (value: T) => {
      return isSelected(value) ? deselect(value) : select(value);
    },
    [isSelected, deselect, select]
  );

  const clear = useCallback(() => {
    setSelection(new Set<T>());
  }, [setSelection]);

  const values = useMemo(() => {
    return Array.from(selection);
  }, [selection]);

  return {
    values,
    isEmpty,
    clear,
    isSelected,
    select,
    deselect,
    toggle,
  };
}

export type UseSelectionStateReturn = ReturnType<typeof useSelectionState>;
