import { motion } from 'framer-motion';
import { forwardRef } from 'react';

import { fadeConfig } from 'components/transitions';
import { x } from 'style';

const MotionDiv = motion(x.div);

export type ModalOverlayProps = {};

export const ModalOverlay = forwardRef<HTMLDivElement, ModalOverlayProps>(function ModalOverlay(
  props,
  ref
) {
  return (
    <MotionDiv
      ref={ref}
      position="fixed"
      zIndex="500"
      left="0"
      top="0"
      bg="rgba(9, 30, 66, 0.54)"
      w="100vw"
      h="100vh"
      {...(fadeConfig as any)}
    />
  );
});
