import { useState } from 'react';

import { Link } from 'components/link';
import { List, ListItem, ListItemMeta } from 'components/list';
import { useApplicationsQuery } from 'data/applications';
import { useOrganizationsQuery } from 'data/organizations';
import { x } from 'style';

const ITEM_LIMIT = 5;

export type GlobalLinksProps = {
  onNavigate?(): void;
};

export function GlobalLinks(props: GlobalLinksProps) {
  const { onNavigate } = props;

  const [showAllApplications, setShowAllApplications] = useState(false);
  const [showAllOrganizations, setShowAllOrganizations] = useState(false);

  const { applications } = useApplicationsQuery();
  const { organizations } = useOrganizationsQuery();

  const toggleShowAllApplications = () => setShowAllApplications((prevValue) => !prevValue);
  const toggleShowAllOrganizations = () => setShowAllOrganizations((prevValue) => !prevValue);

  return (
    <>
      <x.div mb={9}>
        <x.h3 fontSize="md" fontWeight="default" color="gray-600" mb={3}>
          Applications
        </x.h3>

        <List hideDivider mb={2}>
          {(showAllApplications ? applications : [...applications].splice(0, ITEM_LIMIT)).map(
            (item) => (
              <ListItem key={item.id} px={0} py={2}>
                <div>
                  <Link to={`/${item.fullPath}`} onClick={onNavigate}>
                    {item.name}
                  </Link>

                  <ListItemMeta>{item.organization.name}</ListItemMeta>
                </div>
              </ListItem>
            )
          )}
        </List>

        {applications.length >= ITEM_LIMIT ? (
          <x.div
            role="button"
            fontSize="sm"
            color={{ _: 'gray-500', hover: 'primary-500' }}
            onClick={toggleShowAllApplications}
          >
            {showAllApplications ? 'Show less' : 'Show more'}
          </x.div>
        ) : null}
      </x.div>

      <x.div>
        <x.h3 fontSize="md" fontWeight="default" color="gray-600" mb={3}>
          Organizations
        </x.h3>

        <List hideDivider mb={2}>
          {(showAllOrganizations ? organizations : [...organizations].splice(0, ITEM_LIMIT)).map(
            (item) => (
              <ListItem key={item.id} px={0} py={2}>
                <div>
                  <Link to={`/orgs/${item.path}`} onClick={onNavigate}>
                    {item.name}
                  </Link>
                </div>
              </ListItem>
            )
          )}
        </List>

        {organizations.length >= ITEM_LIMIT ? (
          <x.div
            role="button"
            fontSize="sm"
            color={{ _: 'gray-500', hover: 'primary-500' }}
            onClick={toggleShowAllOrganizations}
          >
            {showAllOrganizations ? 'Show less' : 'Show more'}
          </x.div>
        ) : null}
      </x.div>
    </>
  );
}
