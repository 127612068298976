import { ReactNode, forwardRef } from 'react';

import { ModalFocusScope, useModalContext } from 'components/modal';
import { x } from 'style';

import { DrawerContent } from './DrawerContent';

export type DrawerContainerProps = {
  children?: ReactNode;
};

export const DrawerContainer = forwardRef<HTMLDivElement, DrawerContainerProps>((props, ref) => {
  const { scrollBehavior, getDialogContainerProps } = useModalContext();

  return (
    <ModalFocusScope>
      <x.div
        justifyContent="flex-start"
        alignItems="flex-start"
        position="fixed"
        zIndex="501"
        overflow={scrollBehavior === 'inside' ? 'hidden' : 'auto'}
        display="flex"
        left="0"
        top="0"
        w="100vw"
        h="100vh"
        {...getDialogContainerProps()}
      >
        <DrawerContent>{props.children}</DrawerContent>
      </x.div>
    </ModalFocusScope>
  );
});
