import { MouseEvent, forwardRef, useRef, useState } from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';

import { useEventCallback, useMergedRefs } from 'hooks';
import { x } from 'style';

export interface ResizeHandleProps {
  onDrag?(event: DraggableEvent, data: DraggableData): void;
  onCollapse?(): void;
}

export const ResizeHandle = forwardRef<HTMLDivElement, ResizeHandleProps>(
  function SidePanelResizeHandleWithRef(props, ref) {
    const { onCollapse, onDrag } = props;

    const [isHovered, setHovered] = useState(false);
    const [isDragging, setDragging] = useState(false);

    const resizeHandleRef = useRef<HTMLDivElement>(null);
    const rootRef = useMergedRefs(ref, resizeHandleRef);

    const handleDoubleClick = useEventCallback((event: MouseEvent) => {
      event.preventDefault();
      onCollapse?.();
    });

    return (
      <DraggableCore
        nodeRef={resizeHandleRef}
        onDrag={onDrag}
        onStart={() => setDragging(true)}
        onStop={() => setDragging(false)}
      >
        <x.div
          ref={rootRef}
          zIndex="5"
          position="absolute"
          bottom="0px"
          left="100%"
          top="0px"
          w="24px"
          onDoubleClick={handleDoubleClick}
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <x.div position="relative" cursor="ew-resize" left="-4px" h="100%" w="24px">
            <x.div
              transition="opacity, background 200ms ease 0s"
              position="absolute"
              opacity={isHovered ? 1 : 0}
              left="3px"
              bg={isDragging ? 'primary-500' : 'gray-200'}
              h="100%"
              w="2px"
            />
          </x.div>
        </x.div>
      </DraggableCore>
    );
  }
);
