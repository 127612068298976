import { ReactNode, forwardRef } from 'react';

import { StyledProps, x } from 'style';

import { SideLayoutContextProvider, useSideLayoutContextValue } from './SideLayoutContext';

export type SideLayoutProps = StyledProps & {
  children?: ReactNode;

  collapsible?: boolean;

  onCollapse?(): void;
};

export const SideLayout = forwardRef<HTMLDivElement, SideLayoutProps>(function SideLayout(
  props,
  ref
) {
  const { children, style, ...rest } = props;

  const context = useSideLayoutContextValue();

  return (
    <SideLayoutContextProvider value={context}>
      <x.div
        ref={ref}
        position="relative"
        overflow="hidden"
        display="block"
        h="100%"
        w="100%"
        style={{ ...style, translate: 'transform3d(0, 0, 0)' }}
        {...rest}
      >
        {children}
      </x.div>
    </SideLayoutContextProvider>
  );
});
