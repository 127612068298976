import { UseQueryResult, useQueries } from 'react-query';

import { getDataViews } from 'api/data-views';
import { getMimicBoards } from 'api/mimic-boards';
import { getReports } from 'api/reports';
import { ApplicationId, Resource, ResourceEntity, ResourceType } from 'interfaces';

export function useResourcesQuery(applicationId: ApplicationId) {
  const [dataViewsQuery, reportsQuery, mimicBoardsQuery] = useQueries([
    {
      queryKey: ['data-views', { applicationId }],
      queryFn: () => getDataViews(applicationId),
    },
    {
      queryKey: ['reports', { applicationId }],
      queryFn: () => getReports(applicationId),
    },
    {
      queryKey: ['mimic-boards', { applicationId }],
      queryFn: () => getMimicBoards(applicationId),
    },
  ]) as UseQueryResult<ResourceEntity[]>[];

  const entries = Object.fromEntries<ResourceEntity[]>([
    ['dataView', dataViewsQuery.data ?? []],
    ['report', reportsQuery.data ?? []],
    ['mimicBoard', mimicBoardsQuery.data ?? []],
  ]);

  const resources: Resource[] = [];
  for (const key in entries) {
    entries[key as ResourceType].forEach((item) => {
      resources.push({
        type: key as ResourceType,
        resourceId: item.id,
        label: item.name,
        iconUri: item.iconUri,
      });
    });
  }

  return {
    resourceTypes: Object.keys(entries) as ResourceType[],
    resources,
  };
}
