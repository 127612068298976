import { AnimatePresence, motion } from 'framer-motion';
import { Children, ReactElement, ReactNode, Ref, cloneElement, forwardRef } from 'react';

import { Portal } from 'components/portal';
import { ScaleFade } from 'components/transitions';
import { styled } from 'style';

import { UseTooltipOptions, useTooltip } from './useTooltip';

const StyledTooltip = styled.div({
  backgroundColor: 'components.tooltip.bg',
  color: 'components.tooltip.text',

  borderRadius: 'default',
  padding: '2',

  fontSize: 'sm',

  whiteSpace: 'nowrap',
});

const AnimatedTooltip = motion(StyledTooltip);

export type TooltipProps = UseTooltipOptions & {
  label?: ReactNode;

  children?: ReactNode;
};

export const Tooltip = forwardRef(function Tooltip(props: TooltipProps, ref: any) {
  const { children, label, ...rest } = props;

  const tooltip = useTooltip(rest);

  let trigger: ReactElement;
  if (typeof children === 'string') {
    trigger = <span {...tooltip.getTriggerProps()}>{children}</span>;
  } else {
    const child = Children.only(children) as ReactElement & { ref?: Ref<any> };
    trigger = cloneElement(child, tooltip.getTriggerProps(child.props, child.ref));
  }

  return (
    <>
      {trigger}

      <AnimatePresence>
        {tooltip.isOpen && (
          <Portal>
            <div {...tooltip.getTooltipPositionerProps()}>
              <ScaleFade in={tooltip.isOpen}>
                {(motionProps: any) => (
                  <AnimatedTooltip {...motionProps} {...tooltip.getTooltipProps()}>
                    {label}
                  </AnimatedTooltip>
                )}
              </ScaleFade>
            </div>
          </Portal>
        )}
      </AnimatePresence>
    </>
  );
});
