import { IconPlaceholder, SvgIcon } from 'components/icon';
import { List, ListItem } from 'components/list';
import { ResourceLink } from 'components/resource-link';
import { useAssignedWorkflowResources } from 'data/workflows';
import { Application, Resource, WorkflowId } from 'interfaces';
import { styled, x } from 'style';

const StyledResourceLink = styled(ResourceLink)({
  color: 'gray-900',
  textDecoration: 'none',

  alignItems: 'center',
  display: 'flex',
  gap: '3',
});

type WorkflowResourcesProps = {
  application: Application;
  workflowId: WorkflowId;
  onSelect?(resource: Resource): void;
};

export function WorkflowResources(props: WorkflowResourcesProps) {
  const { application, workflowId, onSelect } = props;

  const { assignedResources } = useAssignedWorkflowResources(application.id, workflowId);

  if (!assignedResources.length) {
    return (
      <x.div textAlign="center" color="gray-500" py={12}>
        No resources available
      </x.div>
    );
  }

  return (
    <List hideDivider>
      {assignedResources.map((item) => (
        <ListItem key={`${item.type}.${item.resourceId}`}>
          <x.div flex="1 1 auto">
            <StyledResourceLink
              applicationPath={application.fullPath}
              resourceType={item.type}
              resourceId={item.resourceId}
              onClick={() => onSelect?.(item)}
            >
              {item.iconUri ? (
                <SvgIcon dataUri={item.iconUri} size={6} altText={`${item.label} icon`} />
              ) : (
                <IconPlaceholder size={6} />
              )}

              {item.label}
            </StyledResourceLink>
          </x.div>
        </ListItem>
      ))}
    </List>
  );
}
