import { useCallback, useState } from 'react';

import { useControlledValue } from './useControlledValue';

export interface UseOpenStateProps {
  /**
   * Whether the state is opened. Use in a controlled state.
   */
  isOpen?: boolean;

  /**
   * The default open state.
   */
  defaultOpen?: boolean;

  /**
   * Callback invoked when closed.
   */
  onClose?(): void;

  /**
   * Callback invoked when opened.
   */
  onOpen?(): void;
}

/**
 * Returns a stateful open value, and some utility functions to update it. Can be used in a
 * controlled or uncontrolled component.
 *
 * @example
 *
 * import { useOpenState } from 'hooks';
 *
 * const UncontrolledComponent = () => {
 *    const { isOpen, toggle } = useOpenState();
 *
 *    return (
 *      <button onClick={toggle}>Toggle</button>
 *      {isOpen ? <div>Menu</div> : null}
 *    );
 * }
 *
 * // Note how the parent component now controls the `isOpen` state.
 * const ControlledComponent = ({ isOpen: isOpenProp, onToggle }) => {
 *    const { isOpen } = useOpenState({ isOpen: isOpenProp });
 *
 *    return (
 *      <button onClick={onToggle}>Toggle</button>
 *      {isOpen ? <div>Menu</div> : null}
 *    );
 * }
 */
export function useOpenState(props: UseOpenStateProps = {}) {
  const { defaultOpen = false, isOpen: isOpenProp, onClose, onOpen } = props;
  const [isOpenState, setOpen] = useState(defaultOpen);
  const [isControlled, isOpen] = useControlledValue(isOpenProp, isOpenState);

  const open = useCallback(() => {
    if (!isControlled) {
      setOpen(true);
    }

    onOpen?.();
  }, [isControlled, onOpen]);

  const close = useCallback(() => {
    if (!isControlled) {
      setOpen(false);
    }

    onClose?.();
  }, [isControlled, onClose]);

  const toggle = useCallback(() => {
    isOpen ? close() : open();
  }, [isOpen, open, close]);

  return {
    isOpen,
    open,
    close,
    toggle,
  };
}

export type UseOpenStateReturn = ReturnType<typeof useOpenState>;
