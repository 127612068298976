import { useCallback } from 'react';

import { useModalContext } from 'components/modal';
import { UseOpenStateProps, useOpenState } from 'hooks';

/**
 * Returns a function used to close the current dialog.
 */
export function useCloseDialog() {
  const { onClose } = useModalContext();

  return useCallback(() => onClose?.(), [onClose]);
}

export type UseDialogOptions = UseOpenStateProps & {};

/**
 *
 */
export function useDialog(opts: UseDialogOptions = {}) {
  const { isOpen, open, close, toggle } = useOpenState(opts);

  const getDialogProps = useCallback(() => {
    return {
      isOpen,
      onClose: close,
    };
  }, [close, isOpen]);

  return {
    isOpen,
    open,
    close,
    toggle,
    getDialogProps,
  };
}
