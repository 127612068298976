import { Input } from 'components/input';
import { x } from 'style';

export type SelectMenuSearchProps = {
  value?: string;
  onChange?(value: string): void;
};

export function SelectMenuSearch(props: SelectMenuSearchProps) {
  const { value, onChange } = props;

  return (
    <x.div flexShrink={0}>
      <Input
        placeholder="Search"
        boxShadow="none"
        value={value}
        type="text"
        ring={0}
        border={0}
        flexShrink={0}
        px={4}
        h={12}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </x.div>
  );
}
