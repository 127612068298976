import { createContext } from 'utils';

import { AuthClient } from './AuthClient';

export interface AuthContext {
  client: AuthClient;
}

export const [AuthContextProvider, useAuthContext] = createContext<AuthContext>({});

export function useAuthClient() {
  const { client } = useAuthContext();

  return client;
}
