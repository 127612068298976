import { useEffect } from 'react';

export type UseFileReaderOptions = {
  /**
   * The file to read.
   */
  file?: File | null;

  /**
   *
   * @param this The `FileReader` context.
   */
  onLoad?(result: string | ArrayBuffer | null): void;
};

/**
 * Hook used to read the contents of a `File` item.
 */
export function useFileReader(opts: UseFileReaderOptions) {
  const { file, onLoad: onLoadProp } = opts;

  useEffect(() => {
    if (!file) {
      return;
    }

    function onLoad(this: FileReader) {
      onLoadProp?.(this.result);
    }

    const reader = new FileReader();
    reader.addEventListener('load', onLoad, true);
    reader.readAsDataURL(file);

    return () => {
      reader.removeEventListener('load', onLoad);
    };
  }, [file, onLoadProp]);
}
