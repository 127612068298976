import { StyledProps, x } from 'style';

export type DialogFooterProps = StyledProps & {
  align?: 'start' | 'end';
};

export function DialogFooter(props: DialogFooterProps) {
  const { align = 'end', ...rest } = props;

  return (
    <x.div
      flexDirection="row"
      justifyContent={align === 'start' ? 'flex-start' : 'flex-end'}
      display="flex"
      gap={3}
      pt={0}
      p={6}
      {...rest}
    />
  );
}
