import { ReactNode, Suspense, useRef, useState } from 'react';

import { useAuthClient } from 'auth';
import { useDialog } from 'components/dialog';
import { SideLayout, SideLayoutContent } from 'components/side-layout';
import { SpinnerLoader } from 'components/spinner';
import { usePreferenceState } from 'data/preferences';
import { x } from 'style';

import { ContextualNavContainer } from './ContextualNavContainer';
import { GlobalLayoutContextProvider } from './GlobalLayoutState';
import { GlobalNav } from './GlobalNav';
import { PreferencesDialog } from './PreferencesDialog';
import { QuickNavigation } from './QuickNavigation';

export type GlobalLayoutProps = {
  children?: ReactNode;
};

export function GlobalLayout(props: GlobalLayoutProps) {
  const contextualNavRef = useRef<HTMLDivElement>(null);

  const [selectedDrawer, setSelectedDrawer] = useState<string | null>(null);
  const [navOrientation] = usePreferenceState<'vertical' | 'horizontal'>({
    key: 'navbarOrientation',
    defaultValue: 'vertical',
  });

  const preferencesDialog = useDialog();

  const authClient = useAuthClient();

  const handleSignOut = () => {
    authClient.signOut();
  };

  return (
    <GlobalLayoutContextProvider
      value={{
        contextualNavRef,
      }}
    >
      <x.div
        flexDirection={navOrientation === 'vertical' ? 'row' : 'column'}
        overflow="hidden"
        display="flex"
        h="calc(100vh - 0px)"
      >
        <GlobalNav
          orientation={navOrientation}
          onDrawerSelect={setSelectedDrawer}
          onSignOut={handleSignOut}
          onPreferences={preferencesDialog.open}
        />

        <SideLayout>
          <ContextualNavContainer />

          <SideLayoutContent>
            <Suspense fallback={<SpinnerLoader />}>{props.children}</Suspense>
          </SideLayoutContent>
        </SideLayout>
      </x.div>

      <QuickNavigation
        isOpen={selectedDrawer === 'quickNavigation'}
        onClose={() => setSelectedDrawer(null)}
      />

      <PreferencesDialog {...preferencesDialog.getDialogProps()} />
    </GlobalLayoutContextProvider>
  );
}
