import { createContext } from 'utils';

export interface MenuContext {
  readonly isOpen: boolean;
  open: () => void;
  close: () => void;
}

export const [MenuContextProvider, useMenuContext] = createContext<MenuContext>({
  name: 'MenuContext',
  strict: false,
});
