import { Button } from 'components/button';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  useCloseDialog,
} from 'components/dialog';
import { Switch } from 'components/switch';
import { usePreferenceState } from 'data/preferences';
import { x } from 'style';

import { LayoutPreview } from './LayoutPreview';

export type PreferencesDialogProps = {
  isOpen?: boolean;

  onClose?(): void;
};

export function PreferencesDialog(props: PreferencesDialogProps) {
  const { isOpen, onClose, ...rest } = props;

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <PreferencesDialogContent {...rest} />
    </Dialog>
  );
}

function PreferencesDialogContent() {
  const close = useCloseDialog();

  const [themeMode, setThemeMode] = usePreferenceState({
    key: 'themeMode',
    defaultValue: 'organization',
  });

  const [navOrientation, setNavOrientation] = usePreferenceState<'vertical' | 'horizontal'>({
    key: 'navbarOrientation',
    defaultValue: 'vertical',
  });

  return (
    <>
      <DialogHeader>
        <DialogTitle>Preferences</DialogTitle>
      </DialogHeader>

      <DialogBody>
        <x.div mb={6}>
          <x.h3 fontSize="xl" mb={3}>
            Theme
          </x.h3>

          <Switch
            isChecked={themeMode !== 'organization'}
            onChange={(value) => setThemeMode(value ? 'application' : 'organization')}
          >
            Disable organization theme
          </Switch>
        </x.div>

        <x.div>
          <x.h3 fontSize="xl" mb={3}>
            Layout orientation
          </x.h3>

          <x.div gridTemplateColumns={3} gridAutoFlow="row" display="grid" gap={6}>
            <LayoutPreview
              orientation="horizontal"
              isSelected={navOrientation === 'horizontal'}
              onSelect={() => setNavOrientation('horizontal')}
            />

            <LayoutPreview
              orientation="vertical"
              isSelected={navOrientation === 'vertical'}
              onSelect={() => setNavOrientation('vertical')}
            />
          </x.div>
        </x.div>
      </DialogBody>

      <DialogFooter>
        <Button type="button" onClick={close}>
          Close
        </Button>
      </DialogFooter>
    </>
  );
}
