import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface CardHeaderProps extends StyledProps {}

export const CardHeader = forwardRef<HTMLDivElement, CardHeaderProps>(function CardHeader(
  props,
  ref
) {
  return <x.div ref={ref} color="gray-600" p={6} pb={3} {...props} />;
});
