import { RefObject, useMemo } from 'react';

import { SelectMenu } from 'components/select-menu';

import { useEntityListContext } from './EntityListContext';

export interface EntityListSelectFilterProps<T> {
  filterKey: string;
  labelProp?: string | ((item: T) => any);
  valueProp?: string | ((item: T) => any);
  label?: string;
  source?: T[];
  boundaryRef?: RefObject<HTMLElement>;
}

export function EntityListSelectFilter<T>(props: EntityListSelectFilterProps<T>) {
  const {
    filterKey,
    label = 'Filter',
    labelProp = (item) => item,
    valueProp = (item) => item,
    source = [],
    boundaryRef,
  } = props;

  const { getFilter, setFilter } = useEntityListContext();

  const value = useMemo(() => {
    const filterValue = getFilter(filterKey);
    return filterValue[0];
  }, [filterKey, getFilter]);

  const handleClear = () => {
    setFilter(filterKey, null);
  };

  const handleChange = (value: any) => {
    setFilter(filterKey, value);
  };

  return (
    <SelectMenu
      label={label}
      source={source}
      value={value}
      boundaryRef={boundaryRef}
      labelProp={labelProp}
      valueProp={valueProp}
      onClear={handleClear}
      onChange={handleChange}
    />
  );
}
