import { useMemo } from 'react';

export function useVariantStyles(
  variant: string | null | undefined,
  variants: Record<string, any>
) {
  return useMemo(() => {
    if (!variant || variants[variant] === undefined) {
      return {};
    }

    return variants[variant];
  }, [variant, variants]);
}
