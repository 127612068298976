import { MouseEvent, forwardRef, useMemo } from 'react';

import { StyledProps, x } from 'style';

export type AvatarProps = StyledProps & {
  /**
   * The height and width of the avatar.
   * @default 40px
   */
  size?: number | string;

  /**
   * The text used to create a fallback "icon".
   */
  fallbackText?: string;

  /**
   * Whether the avatar is fully rounded or not.
   */
  isRounded?: boolean;

  /**
   * Event triggered when the avatar is clicked.
   * @param event The `MouseEvent`.
   */
  onClick?(event: MouseEvent): void;
};

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>((props, ref) => {
  const { size = '40px', fallbackText, isRounded = false, ...rest } = props;

  const children = useMemo(() => {
    return (
      <x.span overflow="hidden" cursor="inherit" userSelect="none">
        {fallbackText
          ?.split(/\s+/)
          .slice(0, 2)
          .reduce((abbr, part) => abbr + part[0], '')}
      </x.span>
    );
  }, [fallbackText]);

  return (
    <x.div
      ref={ref}
      cursor="default"
      justifyContent="center"
      borderRadius={isRounded ? 'full' : 'md'}
      alignItems="center"
      fontSize="sm"
      overflow="hidden"
      display="inline-flex"
      outline="none"
      color="gray-600"
      bg="gray-200"
      w={size}
      h={size}
      {...rest}
    >
      {children}
    </x.div>
  );
});
