import { selectorFamily } from 'recoil';

import { getOrganizationTheme } from 'api/organizations';
import { currentOrganization, queryClient } from 'app';

import { AppTheme, createTheme, themeMap } from './theme';
import { createPalette, getContrastingTextColor } from './utils/palettes';

export const themeSelector = selectorFamily<AppTheme, { themeMode: string }>({
  key: 'orgTheme',
  get:
    ({ themeMode }) =>
    async ({ get }) => {
      if (themeMode !== 'organization') {
        return themeMap['primary'];
      }

      const organization = get(currentOrganization);
      if (!organization) {
        return themeMap['primary'];
      }

      const orgTheme = await queryClient.fetchQuery(
        ['organizations', organization.id, 'theme'],
        () => getOrganizationTheme(organization.id)
      );

      if (!orgTheme?.isEnabled) {
        return themeMap['primary'];
      }

      const palette = orgTheme.primaryColor ? createPalette(orgTheme.primaryColor) : {};
      const navbarColor = orgTheme.navbarColor || (palette as any)['primary-700'];
      const navbar = navbarColor
        ? {
            background: navbarColor,
            text: getContrastingTextColor(navbarColor),
          }
        : {};

      return createTheme(palette, (theme) => {
        if (orgTheme.iconUri) {
          theme.logo = orgTheme.iconUri;
        }

        theme.colors.components = {
          ...theme.colors.components,
          navbar: {
            ...theme.colors.components.navbar,
            ...navbar,
          },
        };

        return theme;
      });
    },
});
