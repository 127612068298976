import { generateHexAlphaVariants } from '@xstyled/emotion';
import chroma from 'chroma-js';

export function createPalette(color: string) {
  const intensityMap: {
    [key: number]: number;
  } = {
    50: 0.95,
    100: 0.9,
    200: 0.75,
    300: 0.6,
    400: 0.3,
    600: 0.9,
    700: 0.75,
    800: 0.6,
    900: 0.49,
  };

  const colors = [];
  [50, 100, 200, 300, 400].forEach((level) => {
    colors.push(chroma(color).brighten(intensityMap[level]));
  });

  colors.push(color);

  [600, 700, 800, 900].forEach((level) => {
    colors.push(chroma(color).darken(intensityMap[level]));
  });

  return generateHexAlphaVariants({
    'primary-50': colors[0],
    'primary-100': colors[1],
    'primary-200': colors[2],
    'primary-300': colors[3],
    'primary-400': colors[4],
    'primary-500': colors[5],
    'primary-600': colors[6],
    'primary-700': colors[7],
    'primary-800': colors[8],
    'primary-900': colors[9],
  });
}

export function getContrastingTextColor(color: string, threshold = 4) {
  if (chroma.contrast(color, '#ffffff') >= threshold) {
    return '#ffffff';
  } else {
    return '#000000';
  }
}
