import { ReactChild, ReactNode, forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { DraggableData } from 'react-draggable';

import { useControlledValue, useEventCallback, useMergedRefs } from 'hooks';
import { StyledProps, x } from 'style';

import { ResizeHandle } from './ResizeHandle';
import { useSideLayoutContext } from './SideLayoutContext';
import { SidePanelPosition } from './types';

export type SidePanelRenderChildrenOptions = {
  isCollapsed: boolean;
};

export type SidePanelProps = Omit<StyledProps, 'position'> & {
  children?: ReactChild | ReactChild[] | ((options: SidePanelRenderChildrenOptions) => ReactNode);

  initialWidth?: number;

  width?: number;

  minWidth?: number;

  maxWidth?: number;

  isCollapsed?: boolean;

  isHidden?: boolean;

  initialCollapsed?: boolean;

  isResizable?: boolean;

  isCollapsible?: boolean;

  position?: SidePanelPosition;

  onWidthChange?(width: number): void;

  onCollapsedChanged?(isCollapsed: boolean): void;
};

export const SidePanel = forwardRef<HTMLDivElement, SidePanelProps>((props, ref) => {
  const {
    children,
    initialWidth = 300,
    initialCollapsed = false,
    position = 'start',
    width: widthProp,
    maxWidth,
    minWidth = 100,
    isCollapsed: isCollapsedProp,
    isCollapsible = false,
    isHidden = false,
    isResizable = true,
    onCollapsedChanged,
    ...rest
  } = props;

  const { register, deregister } = useSideLayoutContext();

  const panelRef = useRef<HTMLDivElement>(null);
  const rootRefs = useMergedRefs(ref, panelRef);

  const [isCollapsedState, setCollapsed] = useState(initialCollapsed);
  const [widthState, setWidth] = useState(initialWidth);
  const [isCollapsedControlled, isCollapsed] = useControlledValue(
    isCollapsedProp,
    isCollapsedState
  );
  const [isWidthControlled, width] = useControlledValue(widthProp, widthState);

  const handleDrag = useEventCallback((e: any, data: DraggableData) => {
    const { deltaX } = data;

    let newWidth = Math.max(widthState + deltaX / 1, minWidth);
    if (maxWidth) {
      newWidth = Math.min(newWidth, maxWidth);
    }

    if (!isWidthControlled) {
      setWidth(newWidth);
    }
  });

  const handleCollapse = useEventCallback(() => {
    if (!isCollapsedControlled && isCollapsible) {
      setCollapsed(!isCollapsed);
    }

    onCollapsedChanged?.(!isCollapsed);
  });

  useLayoutEffect(() => {
    register(panelRef, position);
    return () => deregister(position);
  }, [position, register, deregister]);

  const style = {
    right: position === 'end' ? 0 : undefined,
    left: position === 'start' ? 0 : undefined,
    width,
  };

  return (
    <x.div
      ref={rootRefs}
      position="absolute"
      display={isHidden ? 'none' : 'block'}
      bottom="0"
      top="0"
      h="100%"
      style={style}
      {...rest}
    >
      {typeof children === 'function' ? children({ isCollapsed }) : children}

      {isResizable ? <ResizeHandle onDrag={handleDrag} onCollapse={handleCollapse} /> : null}
    </x.div>
  );
});
