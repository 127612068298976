/**
 * Determines if a value is controlled, typically from a component's props, or
 * uncontrolled and managed using internal state.
 * @param prop The external prop value.
 * @param state The internal state value.
 *
 * @example
 * import React from 'react';
 * import { useControlledValue } from 'hooks';
 *
 * const Component = (props) => {
 *    // Note how we don't assign a default value to the `isOpen` prop in the destructed props.
 *    const { isOpen: isOpenProp, onToggle } = props;
 *    // Internal state
 *    const [isOpenState, setOpen] = React.useState(false);
 *    // Final state
 *    const [isControlled, isOpen] = useControlledValue(isOpenProp, isOpenState);
 *
 *    const toggle = React.useCallback(() => {
 *      if (!isControlled) {
 *        setOpen(x => !x);
 *      }
 *
 *      onToggle?.();
 *    }, [isControlled, setOpen, onToggle]);
 *
 *    return (
 *      <button onClick={toggle}>Toggle</button>
 *      {isOpen ? <div>Menu</div> : null}
 *    )
 * }
 */
export function useControlledValue<T>(prop: T | undefined, state: T) {
  const isControlled = prop !== undefined;
  const value = isControlled && typeof prop !== 'undefined' ? prop : state;
  return [isControlled, value] as const;
}
