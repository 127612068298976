import { User, UserManager } from 'oidc-client';

export class AuthClient {
  readonly #userManager = new UserManager({
    authority: process.env.REACT_APP_DECISIO_PATH || window.location.origin,
    redirect_uri: `${window.location.origin}/login-callback`,
    post_logout_redirect_uri: `${window.location.origin}/`,
    client_id: process.env.REACT_APP_CLIENT_ID,
    scope: 'openid profile api',
    response_type: 'code',
    loadUserInfo: true,
    filterProtocolClaims: true,
  });

  // Attempts to authenticate the user.
  async signIn(state: any = null): Promise<boolean> {
    try {
      // First try authenticate silently. This is done using a hidden iframe when the user is
      // already logged in on the IdentityServer provider.
      await this.#userManager.signinSilent();
      return true;
    } catch (e) {
      console.log(`Silent authentication failed: `, e);
      // If silent authentication fails, we attempt a normal sign in redirect, which will take
      // the user to the IdentityServer login page, authenticate them and redirect them back to the
      // app.
      await this.#userManager.signinRedirect({ useReplaceToNavigate: true, data: state });
    }

    return false;
  }

  /**
   * Completes the sign in process.
   * @param url The callback URL.
   * @returns A `Promise` containing the `User` object.
   */
  async completeSignIn(url: string): Promise<User> {
    return await this.#userManager.signinCallback();
  }

  /**
   * Signs the user out.
   * @returns An empty `Promise`.
   */
  signOut(): Promise<void> {
    return this.#userManager.signoutRedirect();
  }

  /**
   * Determines if the user is authenticated.
   */
  async checkAuthenticated(): Promise<boolean> {
    const user = await this.getUser();
    return !!user && !user.expired;
  }

  /**
   * Returns the user's access token.
   * @returns A `Promise` containing the user access token if present; otherwise null.
   */
  async getAccessToken(): Promise<string | null> {
    const user = await this.getUser();
    return user?.access_token || null;
  }

  /**
   * Gets the current user.
   */
  getUser(): Promise<User | null> {
    return this.#userManager.getUser();
  }
}

export const authClient = new AuthClient();
