export type FocusableElement = {
  focus(options?: FocusOptions): void;
};

/**
 * Returns the owner `Document` of the specified node.
 * @param element The HTML element.
 * @returns  The owner `Document`.
 */
export function getOwnerDocument(element?: HTMLElement | null): Document {
  return element instanceof Element ? element.ownerDocument ?? document : document;
}

/**
 * Determines if the element is the active document element.
 * @param element The HTML element.
 * @returns True if the element is active, otherwise false.
 */
export function isActiveElement(element: FocusableElement) {
  const doc = element instanceof HTMLElement ? getOwnerDocument(element) : document;
  return doc.activeElement === (element as HTMLElement);
}

/**
 * Gets the currently active element.
 * @param element The HTML element.
 */
export function getActiveElement(element?: HTMLElement) {
  return getOwnerDocument(element)?.activeElement;
}

export function isHTMLElement(element: any): element is HTMLElement {
  return element instanceof HTMLElement;
}

export function isHidden(element: HTMLElement) {
  if (element.parentElement && isHidden(element.parentElement)) return true;
  return element.hidden;
}

export function isDisabled(element: HTMLElement) {
  return (
    Boolean(element.getAttribute('disabled')) === true ||
    Boolean(element.getAttribute('aria-disabled')) === true
  );
}

export function isContentEditable(element: HTMLElement) {
  const value = element.getAttribute('contenteditable');
  return value !== 'false' && value != null;
}

export const hasTabIndex = (element: HTMLElement) => element.hasAttribute('tabindex');

export const hasNegativeTabIndex = (element: HTMLElement) =>
  hasTabIndex(element) && element.tabIndex === -1;

export function isFocusable(element: Element) {
  if (!isHTMLElement(element) || isHidden(element) || isDisabled(element)) {
    return false;
  }

  const { localName } = element;
  const focusableTags = ['input', 'select', 'textarea', 'button'];
  if (focusableTags.indexOf(localName) >= 0) return true;

  const others = {
    a: () => element.hasAttribute('href'),
    audio: () => element.hasAttribute('controls'),
    video: () => element.hasAttribute('controls'),
  };

  if (localName in others) {
    return others[localName as keyof typeof others]();
  }

  if (isContentEditable(element)) return true;

  return hasTabIndex(element);
}

export function isTabbable(element?: Element | null) {
  if (!element) return false;
  return element instanceof HTMLElement && isFocusable(element) && !hasNegativeTabIndex(element);
}

type ExtendedFocusOptions = FocusOptions & {
  /**
   * Function that determines if the element is the active element
   */
  isActive?: typeof isActiveElement;
  /**
   * If true, the element will be focused in the next tick
   */
  nextTick?: boolean;
  /**
   * If true and element is an input element, the input's text will be selected
   */
  selectTextIfInput?: boolean;
};

export function focus(element: FocusableElement | null, options: ExtendedFocusOptions = {}) {
  const { isActive = isActiveElement, nextTick, preventScroll = true } = options;

  if (!element || isActive(element)) return -1;

  function triggerFocus() {
    if (!element) {
      return;
    }

    if (supportsPreventScroll()) {
      element.focus({ preventScroll });
    } else {
      element.focus();

      if (preventScroll) {
        //   const scrollableElements = getScrollableElements(element as HTMLElement)
        //   restoreScrollPosition(scrollableElements)
      }
    }

    //   if (isInputElement(element) && selectTextIfInput) {
    //     element.select()
    //   }
  }

  if (nextTick) {
    return requestAnimationFrame(triggerFocus);
  }

  triggerFocus();
  return -1;
}

let supportsPreventScrollCached: boolean | null = null;
function supportsPreventScroll() {
  if (!supportsPreventScrollCached) {
    supportsPreventScrollCached = false;
    try {
      const div = document.createElement('div');
      div.focus({
        get preventScroll() {
          supportsPreventScrollCached = true;
          return true;
        },
      });
    } catch (e) {
      // Ignore
    }
  }

  return supportsPreventScrollCached;
}
