import { forwardRef } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { Button, ButtonProps } from './Button';

export type DropdownButtonProps = ButtonProps & {};

export const DropdownButton = forwardRef<HTMLButtonElement, DropdownButtonProps>(
  function DropdownButton(props, forwardedRef) {
    const { children, ...rest } = props;

    return (
      <Button
        ref={forwardedRef}
        bg={{ _: 'transparent', hover: 'gray-200', active: 'gray-300' }}
        {...rest}
        gap={3}
      >
        {children}
        <FiChevronDown />
      </Button>
    );
  }
);
