import { RefObject, useCallback, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

// import { useResizeObserver } from 'hooks';

import { createContext } from 'utils';

import { ContentMargins, SidePanelPosition } from './types';

export type SideLayoutContext = {
  margins: ContentMargins;
  register(ref: RefObject<HTMLDivElement>, position: SidePanelPosition): void;
  deregister(position: SidePanelPosition): void;
};

export const [SideLayoutContextProvider, useSideLayoutContext] = createContext<SideLayoutContext>({
  strict: true,
  name: 'SideLayoutContext',
});

export function useSideLayoutContextValue(): SideLayoutContext {
  const [margins, setMargins] = useState<ContentMargins>({ left: 0, right: 0 });

  const leftPanel = useRef<HTMLDivElement | null>(null);
  const rightPanel = useRef<HTMLDivElement | null>(null);

  const register = useCallback((ref: RefObject<HTMLDivElement>, position: SidePanelPosition) => {
    const panelRef = position === 'start' ? leftPanel : rightPanel;

    if (panelRef.current && !panelRef.current.isSameNode(ref.current)) {
      throw Error('Cannot register more than one side panel for a single position.');
    }

    panelRef.current = ref.current;
    if (panelRef.current) {
      const { width } = panelRef.current.getBoundingClientRect();
      setMargins((prevValue) => ({
        ...prevValue,
        [position === 'start' ? 'left' : 'right']: width,
      }));
    }
  }, []);

  const deregister = useCallback((position: SidePanelPosition) => {
    const panelRef = position === 'start' ? leftPanel : rightPanel;
    panelRef.current = null;
    setMargins((prevValue) => ({ ...prevValue, [position === 'start' ? 'left' : 'right']: 0 }));
  }, []);

  useResizeObserver({
    ref: leftPanel,
    onResize: (size) => setMargins((prevValue) => ({ ...prevValue, left: size?.width ?? 0 })),
  });

  useResizeObserver({
    ref: rightPanel,
    onResize: (size) => setMargins((prevValue) => ({ ...prevValue, right: size?.width ?? 0 })),
  });

  return {
    margins,
    register,
    deregister,
  };
}
