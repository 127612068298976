import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface PopoverHeaderProps extends StyledProps {}

export const PopoverHeader = forwardRef<HTMLDivElement, PopoverHeaderProps>(
  function PopoverHeaderWithRef(props, ref) {
    return <x.div ref={ref} px={4} pt={3} pb={0} {...props} />;
  }
);
