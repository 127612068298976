import { forwardRef } from 'react';
import { Link as BaseLink, LinkProps as BaseLinkProps } from 'react-router-dom';

import { StyledProps, x } from 'style';

export interface LinkProps
  extends StyledProps,
    Pick<BaseLinkProps, 'to' | 'replace' | 'state' | 'target'> {}

export const Link = forwardRef<any, LinkProps>(function Link(props, ref) {
  return <x.div ref={ref} as={BaseLink} color="primary-500" textDecoration="none" {...props} />;
});
