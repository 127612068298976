import { Preflight, createGlobalStyle } from '@xstyled/emotion';

export const RootStyles = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 14px;
    overflow: hidden;
  }

  body {
    font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', 'Noto Color Emoji';

    background-color: background;
    color: text;
  }

  b,
  strong {
    font-weight: 500;
  }

  .gsfFeedback {
    border-color: #7de7cf;
    font-size: 11;
    margin-bottom: 7;
  }
`;

export const GlobalStyles = () => {
  return (
    <>
      <Preflight />
      <RootStyles />
    </>
  );
};
