import { forwardRef } from 'react';

import { Button, ButtonProps } from './Button';

export interface IconButtonProps extends ButtonProps {}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(function IconButton(
  props,
  ref
) {
  const { isDisabled } = props;
  const nonDisabledProps = !isDisabled
    ? {
        bg: {
          hover: 'rgba(0, 0, 0, 0.075)',
          focus: 'rgba(0, 0, 0, 0.075)',
          active: 'rgba(0, 0, 0, 0.125)',
        },
      }
    : {
        cursor: 'default',
        opacity: 0.5,
      };

  return (
    <Button
      ref={ref}
      type="button"
      borderRadius="full"
      isDisabled={isDisabled}
      background="none"
      padding={2}
      border="none"
      color="currentColor"
      {...nonDisabledProps}
      {...props}
    />
  );
});
