import { MouseEvent, ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

import { x } from 'style';

export interface TabLabelProps {
  children?: ReactNode;

  /**
   * Whether the tag is selected.
   */
  isSelected?: boolean;
  /**
   * Whether the tab is dismissible.
   */
  isDismissible?: boolean;

  /**
   * Whether or not the label is stretched.
   */
  isStretched?: boolean;

  onClick?(): void;

  onDismiss?(): void;
}

export function TabLabel(props: TabLabelProps) {
  const { children, isDismissible, isSelected, isStretched = false, onClick, onDismiss } = props;

  const handleDismissButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    onDismiss?.();
  };

  return (
    <x.div
      role="tab"
      aria-selected={isSelected}
      justifyContent="center"
      alignItems="center"
      flexGrow={isStretched ? 1 : undefined}
      tabIndex={isSelected ? 1 : -1}
      outline="none"
      display="flex"
      color={isSelected ? 'primary-500' : 'gray-600'}
      cursor="pointer"
      px={4}
      h={10}
      onClick={onClick}
    >
      {children}

      {isDismissible && (
        <x.div
          aria-label="Dismiss tab"
          whiteSpace="nowrap"
          userSelect="none"
          color={{ _: 'gray-500', active: 'gray-800' }}
          role="button"
          ml={3}
          onClick={handleDismissButtonClick}
        >
          <FiX />
        </x.div>
      )}
    </x.div>
  );
}
