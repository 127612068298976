import { ReactNode, forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface FieldProps extends StyledProps {
  children?: ReactNode;
}

export const Field = forwardRef<HTMLDivElement, FieldProps>(function FieldWithRef(props, ref) {
  const { children, ...otherProps } = props;

  return (
    <x.div ref={ref} {...otherProps} mb={6}>
      {children}
    </x.div>
  );
});
