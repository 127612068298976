import {
  ApplicationId,
  CreateWorkflowPayload,
  SetWorkflowGroupsPayload,
  SetWorkflowResourcesPayload,
  UpdateWorkflowPayload,
  Workflow,
  WorkflowGroup,
  WorkflowId,
  WorkflowResource,
} from 'interfaces';

import { api } from './api';

/**
 * Gets the application workflows.
 * @param applicationId The application ID.
 * @returns The workflows.
 */
export function getWorkflows(applicationId: ApplicationId) {
  return api.get<Workflow[]>(`/applications/${applicationId}/workflows`);
}

/**
 * Gets an application workflow.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 * @returns The workflow.
 */
export function getWorkflow(applicationId: ApplicationId, workflowId: WorkflowId) {
  return api.get<Workflow>(`/applications/${applicationId}/workflows/${workflowId}`);
}

/**
 * Creates a new application workflow.
 * @param applicationId The application ID.
 * @param payload The request payload.
 * @returns The created workflow.
 */
export function createWorkflow(applicationId: ApplicationId, payload: CreateWorkflowPayload) {
  return api.post<Workflow>(`/applications/${applicationId}/workflows`, payload);
}

/**
 * Updates an existing application workflow.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 * @param payload The request payload.
 * @returns The updated workflow.
 */
export function updateWorkflow(
  applicationId: ApplicationId,
  workflowId: WorkflowId,
  payload: UpdateWorkflowPayload
) {
  return api.put<Workflow>(`/applications/${applicationId}/workflows/${workflowId}`, payload);
}

/**
 * Deletes an existing application workflow.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 * @returns An empty promise.
 */
export function deleteWorkflow(applicationId: ApplicationId, workflowId: WorkflowId) {
  return api.delete(`/applications/${applicationId}/workflows/${workflowId}`);
}

/**
 * Gets the application workflow resources.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 * @returns The workflow resources.
 */
export function getWorkflowResources(applicationId: ApplicationId, workflowId: WorkflowId) {
  return api.get<WorkflowResource[]>(
    `/applications/${applicationId}/workflows/${workflowId}/resources`
  );
}

/**
 * Sets the application workflow resources.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 * @param payload The request payload.
 * @returns The updated workflow resources.
 */
export function setWorkflowResources(
  applicationId: ApplicationId,
  workflowId: WorkflowId,
  payload: SetWorkflowResourcesPayload
) {
  return api.patch<WorkflowResource[]>(
    `/applications/${applicationId}/workflows/${workflowId}/resources`,
    payload
  );
}

/**
 * Gets the application workflow groups.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 * @returns The workflow groups.
 */
export function getWorkflowGroups(applicationId: ApplicationId, workflowId: WorkflowId) {
  return api.get<WorkflowGroup[]>(`/applications/${applicationId}/workflows/${workflowId}/groups`);
}

/**
 * Sets the application workflow groups.
 * @param applicationId The application ID.
 * @param workflowId The workflow ID.
 * @param payload The request payuload.
 * @returns The workflow groups.
 */
export function setWorkflowGroups(
  applicationId: ApplicationId,
  workflowId: WorkflowId,
  payload: SetWorkflowGroupsPayload
) {
  return api.patch<WorkflowGroup[]>(
    `/applications/${applicationId}/workflows/${workflowId}/groups`,
    payload
  );
}
