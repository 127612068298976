import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { ReactElement, Ref, forwardRef } from 'react';

import { TransitionDefaults, Variants, withDelay } from './utils';

const variants: Variants = {
  enter: ({ transition, transitionEnd, delay } = {}) => ({
    opacity: 1,
    transition: transition?.enter ?? withDelay.enter(TransitionDefaults.enter, delay),
    transitionEnd: transitionEnd?.enter,
  }),
  exit: ({ transition, transitionEnd, delay } = {}) => ({
    opacity: 0,
    transition: transition?.exit ?? withDelay.exit(TransitionDefaults.exit, delay),
    transitionEnd: transitionEnd?.exit,
  }),
};

export const fadeConfig: HTMLMotionProps<'div'> = {
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
  variants,
};

export type FadeProps = HTMLMotionProps<'div'> & {
  children?:
    | ReactElement
    | ((motionProps: HTMLMotionProps<'div'>, ref: Ref<HTMLDivElement>) => ReactElement);
  in?: boolean;
};

export type FadePresenceProps = FadeProps & {
  unmountOnExit?: boolean;
};

export const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: isOpen, children, transition, ...rest } = props;

  const custom = {
    transition,
  };

  const animate = isOpen ? 'enter' : 'exit';

  const motionProps = {
    custom,
    ...fadeConfig,
    animate,
    ...rest,
  };

  return typeof children === 'function' ? (
    children(motionProps, ref)
  ) : (
    <motion.div {...motionProps}>{children}</motion.div>
  );
});

export const FadePresence = forwardRef<HTMLDivElement, FadePresenceProps>(function Fade(
  props,
  ref
) {
  const { in: isOpen, unmountOnExit, transition, ...rest } = props;

  const custom = {
    transition,
  };

  const show = unmountOnExit ? isOpen && unmountOnExit : true;

  return (
    <AnimatePresence custom={custom}>
      {show && <Fade in={isOpen} custom={custom} {...rest} />}
    </AnimatePresence>
  );
});
