import {
  Application,
  ApplicationDashboardConfiguration,
  ApplicationId,
  ApplicationResource,
  CreateApplicationPayload,
  OrganizationGroupId,
  TransferApplicationPayload,
  UpdateApplicationDashboardConfigurationPayload,
  UpdateApplicationGroupsPayload,
  UpdateApplicationPayload,
} from 'interfaces';

import { api, toFormData } from './api';

/**
 * Fetches all applications for the current user.
 * @return A `Promise` that resolves to the list of applications.
 */
export function getApplications() {
  return api.get<Application[]>('/applications');
}

/**
 * Fetches the application by either it's path or ID.
 * @param applicationPathOrId The application path or ID.
 * @returns A `Promise` that resolves to the application if found; otherwise null.
 */
export async function getApplication(applicationPathOrId: string | ApplicationId) {
  return api.get<Application>(`/applications/${applicationPathOrId}`);
}

/**
 * Creates a new application.
 * @param payload The request payload used to create the application.
 * @return A `Promise` of the created application.
 */
export function createApplication(payload: CreateApplicationPayload) {
  return api.post<Application>(`/applications`, payload);
}

/**
 * Updates an existing application.
 * @param applicationId The application ID.
 * @param payload The request payload used to update the application.
 * @return A `Promise` of the updated application.
 */
export function updateApplication(applicationId: ApplicationId, payload: UpdateApplicationPayload) {
  return api.put<Application>(`/applications/${applicationId}`, payload);
}

/**
 * Transfers a application to another organization.
 * @param applicationId The application ID.
 * @param payload The request payload used to transfer the application.
 * @return A `Promise` of the updated application.
 */
export function transferApplication(
  applicationId: ApplicationId,
  payload: TransferApplicationPayload
) {
  return api.post<Application>(`/applications/${applicationId}/transfer`, payload);
}

/**
 * Fetches the permitted resources for an application and organization group.
 * @return A `Promise` of the list of application resources.
 */
export function getApplicationResources(
  applicationId: ApplicationId,
  organizationGroupId: OrganizationGroupId
) {
  return api.get<ApplicationResource[]>(
    `/applications/${applicationId}/groups/${organizationGroupId}/resources`
  );
}

/**
 * Updates the permitted resources for the specified application and group.
 * @param applicationId The ID of the application.
 * @param organizationGroupId The ID of the group.
 * @param command The command used to update the application resources.
 */
export function updateApplicationResources(
  applicationId: ApplicationId,
  organizationGroupId: OrganizationGroupId,
  payload: UpdateApplicationPayload
) {
  return api.post<ApplicationResource[]>(
    `/applications/${applicationId}/groups/${organizationGroupId}/resources`,
    payload
  );
}

/**
 * Fetches the permitted organization groups for an EO application.
 * @param applicationId The ID of the application.
 * @param organizationGroupId The ID of the group.
 * @return A `Promise` of the list of application group IDs.
 */
export function getApplicationGroups(applicationId: ApplicationId) {
  return api.get<OrganizationGroupId[]>(`/applications/${applicationId}/groups`);
}

/**
 * Updates the permitted groups for the specified application.
 */
export function updateApplicationGroups(
  applicationId: ApplicationId,
  payload: UpdateApplicationGroupsPayload
) {
  return api.post<OrganizationGroupId[]>(`/applications/${applicationId}/groups`, payload);
}

/**
 * Gets the application dashboard configuration.
 * @param applicationId The application ID.
 * @returns
 */
export function getApplicationDashboardConfiguration(applicationId: ApplicationId) {
  return api.get<ApplicationDashboardConfiguration>(`/applications/${applicationId}/dashboard`);
}

/**
 * Updates the application dashboard configuration.
 * @param applicationId The application ID.
 * @returns
 */
export function updateApplicationDashboardConfiguration(
  applicationId: ApplicationId,
  payload: UpdateApplicationDashboardConfigurationPayload
) {
  return api.request<void>(`/applications/${applicationId}/dashboard`, 'POST', {
    data: toFormData(payload),
    responseType: 'json',
  });
}
