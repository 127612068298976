import { x } from 'style';

import { Input, InputProps } from './Input';

export type SearchInputProps = InputProps;

export const SearchInput = (props: SearchInputProps) => {
  return (
    <x.div position="relative">
      <Input
        placeholder="Search"
        bg={{ _: 'gray-200', focus: 'white' }}
        borderColor={{ _: 'gray-300', focus: 'primary-500' }}
        borderRadius="lg"
        boxShadow="0 0 #000"
        pr={9}
        {...props}
      />
    </x.div>
  );
};
