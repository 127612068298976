import { ReactNode } from 'react';
import { FiChevronRight } from 'react-icons/fi';

import { x } from 'style';

export interface BreadcrumbProps {
  children?: ReactNode;

  isActive?: boolean;
}

export function Breadcrumb(props: BreadcrumbProps) {
  const { children, isActive = false } = props;

  return (
    <x.div
      alignItems="center"
      display="inline-flex"
      cursor="default"
      lineHeight={8}
      h={8}
      color={isActive ? 'gray-600' : 'gray-400'}
    >
      <x.div mr={3}>{children}</x.div>

      {!isActive && <FiChevronRight />}
    </x.div>
  );
}
