import { ReactElement } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';

import { useModalContext } from './Modal';

export type ModalFocusScopeProps = {
  children: ReactElement;
};

export function ModalFocusScope(props: ModalFocusScopeProps) {
  const {
    blockScrollOnMount = true,
    allowPinchZoom = false,
    autoFocus = true,
    trapFocus = true,
    removeScrollBar,
  } = useModalContext();

  return (
    <FocusLock autoFocus={autoFocus} disabled={!trapFocus}>
      <RemoveScroll
        removeScrollBar={removeScrollBar}
        allowPinchZoom={allowPinchZoom}
        forwardProps
        enabled={blockScrollOnMount}
      >
        {props.children}
      </RemoveScroll>
    </FocusLock>
  );
}
