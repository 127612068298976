import { Modal, ModalOverlay, ModalProps } from 'components/modal';

import { DrawerContainer } from './DrawerContainer';

export type DrawerProps = ModalProps & {};

export function Drawer(props: DrawerProps) {
  const { children, ...rest } = props;

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <DrawerContainer>{children}</DrawerContainer>
    </Modal>
  );
}
