import { x } from 'style';

export type LayoutPreviewProps = {
  navbarColor?: any;
  isSelected?: boolean;
  orientation?: 'vertical' | 'horizontal';
  onSelect?(): void;
};

export function LayoutPreview(props: LayoutPreviewProps) {
  const { navbarColor = 'gray-300', isSelected, orientation = 'horizontal', onSelect } = props;

  return (
    <x.div
      borderRadius="md"
      flexDirection={orientation === 'vertical' ? 'row' : 'column'}
      ringColor="primary-500"
      boxShadow="0 0 #000"
      overflow="hidden"
      display="flex"
      cursor="pointer"
      ring={isSelected ? 1 : undefined}
      gap={2}
      bg="hsl(218, 31%, 95%)"
      h={24}
      p={2}
      onClick={onSelect}
    >
      <x.div
        borderRadius="sm"
        bg={navbarColor}
        h={orientation === 'horizontal' ? 4 : undefined}
        w={orientation === 'vertical' ? 4 : undefined}
      />

      <x.div flexGrow={1} display="flex" flexDirection="column">
        <x.div borderRadius="sm" boxShadow="sm" flexGrow="1" ring={0} bg="white"></x.div>
      </x.div>
    </x.div>
  );
}
