import { useLayoutEffect, useState } from 'react';

let id = 0;
const genId = () => ++id;

export function useId(idFromProps?: string | null) {
  const initialId = idFromProps || genId();
  const [id, setId] = useState(initialId);

  useLayoutEffect(() => {
    if (id === null) {
      setId(genId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return id !== null ? String(id) : undefined;
}

export function useIds(idFromProps?: string | null, ...prefixes: string[]): string[] {
  const id = useId(idFromProps);
  return prefixes.map((prefix) => `${prefix}-${id}`);
}
