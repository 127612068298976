import { Suspense } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useRecoilState } from 'recoil';

import { currentWorkflowIdState } from 'app';
import { IconButton } from 'components/button';
import { Stack } from 'components/layout';
import { SpinnerLoader } from 'components/spinner';
import { Tab, Tabs } from 'components/tabs';
import { Tooltip } from 'components/tooltip';
import { TruncateText } from 'components/typography';
import { Application } from 'interfaces';
import { x } from 'style';

import { Favorites } from './Favorites';
import { Workflows } from './Workflows';

type AppLinksProps = {
  application: Application;
  onShowAll?(): void;
};

export function AppLinks(props: AppLinksProps) {
  const { application, onShowAll } = props;

  const [currentWorkflowId] = useRecoilState(
    currentWorkflowIdState({ applicationId: application.id })
  );

  return (
    <>
      <Stack mb={6}>
        <Tooltip label="Show all">
          <IconButton onClick={onShowAll}>
            <FiArrowLeft />
          </IconButton>
        </Tooltip>

        <x.h2 fontSize="lg" fontWeight="medium">
          <TruncateText>{application.name}</TruncateText>
        </x.h2>
      </Stack>

      <Tabs initialSelectedIndex={currentWorkflowId ? 1 : 0}>
        <Tab label="Favourites">
          <Suspense fallback={<SpinnerLoader />}>
            <Favorites />
          </Suspense>
        </Tab>

        <Tab label="Workflows">
          <Suspense fallback={<SpinnerLoader />}>
            <Workflows application={application} />
          </Suspense>
        </Tab>
      </Tabs>
    </>
  );
}
