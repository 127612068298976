import {
  Context,
  Provider,
  createContext as createReactContext,
  useContext as useReactContext,
} from 'react';

export interface CreateContextOptions {
  name?: string;
  strict?: boolean;
}

export type CreateContextReturn<TContext> = [
  provider: Provider<TContext>,
  useContext: () => TContext,
  context: Context<TContext>
];

export function createContext<TContext>(
  options: CreateContextOptions = {}
): CreateContextReturn<TContext> {
  const { name, strict = true } = options;

  const Context = createReactContext<TContext | undefined>(undefined);
  Context.displayName = name;

  function useContext() {
    const context = useReactContext(Context);
    if (!context && strict) {
      throw new Error(`"context" is undefined: Make sure to wrap component with Provider.`);
    }
    return context;
  }

  return [Context.Provider, useContext, Context] as CreateContextReturn<TContext>;
}
