import { ChangeEvent } from 'react';

import { SearchInput } from 'components/input';

import { useEntityListContext } from './EntityListContext';

export function EntityListSearch() {
  const { queryString, setQueryString } = useEntityListContext();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQueryString(event.currentTarget.value);
  };

  return <SearchInput value={queryString} aria-label="Search" onChange={handleChange} />;
}
