export interface LogoProps {
  url?: string;
  size?: number | string;
}

export const Logo = (props: LogoProps) => {
  const { url = null, size = 48 } = props;

  if (url !== null) {
    return <img width={size} height={size} src={url} alt="Logo" />;
  }

  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 48 48"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(.99608 0 0 .97148 -31.639 -28.321)">
        <path
          d="m58.32 29.171h-20.818c-3.673-0.407-6.94 6.124-5.307 8.571 1.634-1.632 2.858-3.674 4.899-3.674h20c11.838 2.042 17.143 11.024 17.143 19.595 0 9.797-8.98 19.592-19.592 19.592-1.225 0-5.611-0.408-5.715-4.488-0.014-0.545-0.018-0.84-0.01-0.998 0-8e-3 -4e-3 -0.014-4e-3 -0.02 0-0.018 6e-3 -0.033 0.01-0.049 8e-3 -0.068 0.02-0.096 0.041-0.1 0.047-0.07 0.123-0.117 0.213-0.117 0.064 0 0.119 0.025 0.166 0.063 0.119 0.033 0.242 0.057 0.365 0.086 1.27 0.049 3.928 0.111 6-0.082 6.342-1.412 12.813-6.643 12.813-13.887 0-5.306-4.082-11.839-10.613-13.474-0.783-0.173-1.678-0.289-2.611-0.366h-14.912c-3.68 0.35-7.476 1.612-8.192 5.676v27.35c3.126 5.713 10.612 5.713 10.612 5.713-2.04-2.447-4.49-4.078-5.306-7.348v-24.491c-0.183-1.859 0.854-1.548 1.632-1.63h4.49v25.715c0 4.898 6.939 7.754 10.613 7.754 12.244 0 25.715-10.203 25.715-24.898 1e-3 -10.204-7.345-22.042-21.632-24.493zm-9.485 30.704v-14.375h6.627c0.061 0.012 0.117 0.028 0.178 0.041 4.271 0.413 7.613 4.011 7.613 8.393 0 4.658-3.775 8.434-8.434 8.434h-5.984z"
          clipRule="evenodd"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
