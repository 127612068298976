import { useCallback, useState } from 'react';
import { SearchParserKeyWordOffset } from 'search-query-parser';

import { UseCollectionListOptions, useCollectionList } from 'hooks';

export type UseBaseEntityListProps<T> = UseCollectionListOptions<T> & {
  setQueryString(value: string | ((prevValue: string) => string)): void;
};

/**
 * Base entity list hook.
 * @param props Base options.
 * @returns
 */
export function useBaseEntityList<T>(props: UseBaseEntityListProps<T>) {
  const {
    filters,
    page = 1,
    perPage = 10,
    searchKeys,
    source,
    sorting,
    queryString,
    setQueryString,
  } = props;

  const { items, isFiltered, pageCount, query, parsedQuery } = useCollectionList<T>({
    source,
    queryString,
    searchKeys,
    filters,
    sorting,
    page,
    perPage,
  });

  /**
   * Updates the query.
   */
  const updateQuery = useCallback(
    (key: string, newValue: any | null) => {
      let value: string = '';
      if (newValue !== null) {
        const escapedValue = new RegExp(/\s+/g).test(newValue) ? `"${newValue}"` : newValue;
        value = `${key}:${escapedValue}`;
      }

      const offset = parsedQuery?.offsets?.find(
        (x) => (x as SearchParserKeyWordOffset)?.keyword === key
      );
      if (offset) {
        setQueryString((q) => {
          return (q.substr(0, offset.offsetStart) + value + q.substr(offset.offsetEnd))
            .replace(/\s+/g, ' ')
            .trim();
        });
      } else {
        setQueryString((q) => `${q} ${value} `.replace(/\s+/g, ' ').trimStart());
      }
    },
    [parsedQuery, setQueryString]
  );

  const resetQuery = () => {
    setQueryString('');
  };

  return {
    items,
    isFiltered,
    query,
    pageCount,
    updateQuery,
    resetQuery,
  };
}

export type UseEntityListProps<T> = Omit<
  UseBaseEntityListProps<T>,
  'page' | 'queryString' | 'setQueryString'
>;

/**
 * In-memory implementation of the entity list.
 * @param props
 */
export function useEntityList<T>(props: UseEntityListProps<T>) {
  const { filters, source, searchKeys, sorting, perPage = 10 } = props;
  const [queryString, setQueryString] = useState('');
  const [page, setPage] = useState(1);

  const { items, isFiltered, pageCount, query, updateQuery, resetQuery } = useBaseEntityList({
    filters,
    perPage,
    page,
    searchKeys,
    source,
    sorting,
    queryString,
    setQueryString,
  });

  const setSorting = useCallback(
    (prop: string, dir?: string) => {
      updateQuery('sort', `${prop}-${dir}`);
    },
    [updateQuery]
  );

  const setFilter = useCallback(
    (key: string, value: string | null) => {
      setPage(1);
      if (value === null || query.filters.get(key)?.has(value)) {
        updateQuery(key, null);
      } else {
        updateQuery(key, value);
      }
    },
    [query, updateQuery]
  );

  const getFilter = useCallback(
    (key: string) => (query.filters.has(key) ? Array.from(query.filters.get(key)!) : []),
    [query]
  );

  return {
    items,
    isFiltered,
    query,
    sorting,
    pageCount,
    queryString,
    getFilter,
    setFilter,
    setQueryString,
    setSorting,
    setPage,
    resetQuery,
  };
}
