import { createContext } from 'utils';

export interface PopoverContext {
  /**
   * Whether the popover is open.
   */
  readonly isOpen: boolean;

  /**
   * Opens the popover.
   */
  open(): void;

  /**
   * Closes the popover.
   */
  close(): void;
}

export const [PopoverContextProvider, usePopoverContext] = createContext<PopoverContext>({
  name: 'PopoverContext',
});
