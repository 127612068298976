import { forwardRef, useMemo } from 'react';

import { x } from 'style';
import type * as Polymorphic from 'style/polymorphic';

const DEFAULT_TAG = 'div';

export type IconPlaceholderProps = {
  size?: number | string;

  color?: string;

  fallbackText?: string;
};

export const IconPlaceholder = forwardRef(function IconPlaceholder(props, ref) {
  const { as = DEFAULT_TAG, size = '24px', color = 'gray-200', fallbackText, ...rest } = props;

  const children = useMemo(() => {
    return (
      <x.span overflow="hidden" cursor="inherit" userSelect="none">
        {fallbackText
          ?.split(/\s+/)
          .slice(0, 2)
          .reduce((abbr, part) => abbr + part[0], '')}
      </x.span>
    );
  }, [fallbackText]);

  return (
    <x.div
      ref={ref}
      as={as}
      borderRadius="full"
      justifyContent="center"
      alignItems="center"
      fontSize="sm"
      overflow="hidden"
      outline="none"
      display="inline-flex"
      color="gray-600"
      bg={color}
      w={size}
      h={size}
      {...rest}
    >
      {children}
    </x.div>
  );
}) as Polymorphic.ForwardRefComponent<typeof DEFAULT_TAG, IconPlaceholderProps>;
