import { ReactNode, forwardRef } from 'react';

import { StyledProps, x } from 'style';

interface ContainerProps extends StyledProps {
  children?: ReactNode;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(function ContainerWithRef(
  props,
  ref
) {
  return <x.div ref={ref} container mx="auto" px={6} {...props} />;
});
