// eslint-disable-next-line sort-imports
import { KeyboardEvent, ReactNode, forwardRef, useRef, useLayoutEffect } from 'react';

import { useEventCallback, useListFocus, useMergedRefs } from 'hooks';
import { styled } from 'style';

export const StyledMenuList = styled.div({
  outline: 'none',
  flex: '1 1 auto',
  padding: '2 0',
  overflowX: 'auto',
});

export type MenuListProps = {
  children?: ReactNode;
  autoFocus?: boolean;
  disableListWrap?: boolean;
  onKeyDown?(event: KeyboardEvent): void;
};

export const MenuList = forwardRef<HTMLDivElement, MenuListProps>(function MenuList(props, ref) {
  const { children, disableListWrap = false, autoFocus = true, onKeyDown, ...otherProps } = props;

  const listRef = useRef<HTMLDivElement>(null);
  const handleRef = useMergedRefs(ref, listRef);

  const { focusNextItem, focusPreviousItem, focusFirstItem, focusLastItem } = useListFocus({
    ref: listRef,
    disableListWrap,
  });

  const handleKeyDown = useEventCallback((event: KeyboardEvent) => {
    const key = event.key;

    if (key === 'ArrowDown') {
      event.preventDefault();
      focusNextItem();
    } else if (key === 'ArrowUp') {
      event.preventDefault();
      focusPreviousItem();
    } else if (key === 'Home') {
      event.preventDefault();
      focusFirstItem();
    } else if (key === 'End') {
      event.preventDefault();
      focusLastItem();
    }

    onKeyDown?.(event);
  });

  useLayoutEffect(() => {
    if (autoFocus) {
      // listRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledMenuList
      ref={handleRef}
      tabIndex={autoFocus ? 0 : -1}
      onKeyDown={handleKeyDown}
      {...otherProps}
    >
      {children}
    </StyledMenuList>
  );
});
