import { useCallback, useRef } from 'react';

import {
  UsePopperOptions,
  popperCSSVars,
  useIds,
  useOpenState,
  usePopper,
  useUnmountEffect,
} from 'hooks';
import { mergeProps } from 'utils';

export type UseTooltipOptions = Pick<
  UsePopperOptions,
  'placement' | 'gutter' | 'offset' | 'modifiers'
> & {
  id?: string;
  defaultOpen?: boolean;
  onClose?(): void;
  openDelay?: number;
  closeDelay?: number;
  isDisabled?: boolean;
  closeOnClick?: boolean;
};

export function useTooltip(opts: UseTooltipOptions = {}) {
  const {
    id,
    placement,
    gutter,
    offset,
    modifiers,
    isDisabled = false,
    openDelay = 0,
    closeDelay = 0,
    defaultOpen = false,
    onClose,
    closeOnClick = true,
  } = opts;

  const openTimeoutRef = useRef<number>(0);
  const closeTimeoutRef = useRef<number>(0);

  const { isOpen, open, close } = useOpenState({ defaultOpen, onClose });
  const [tooltipId] = useIds(id, 'tooltip');

  const popper = usePopper({
    placement,
    gutter,
    offset,
    modifiers,
  });

  const openWithDelay = useCallback(() => {
    if (!isDisabled) {
      openTimeoutRef.current = setTimeout(open, openDelay) as unknown as number;
    }
  }, [isDisabled, openDelay, open]);

  const closeWithDelay = useCallback(
    (timeout?: number) => {
      if (openTimeoutRef.current) {
        clearTimeout(openTimeoutRef.current);
      }

      if (isOpen) {
        closeTimeoutRef.current = setTimeout(
          close,
          typeof timeout === 'number' ? timeout : closeDelay
        ) as unknown as number;
      }
    },
    [isOpen, closeDelay, close]
  );

  const onClick = useCallback(() => {
    if (closeOnClick) {
      closeWithDelay(0);
    }
  }, [closeOnClick, closeWithDelay]);

  const getTriggerProps = useCallback(
    (props = {}, ref = null) => {
      const triggerProps = mergeProps(props, {
        onClick,
        onFocus: openWithDelay,
        onBlur: closeWithDelay,
        onMouseEnter: openWithDelay,
        onMouseLeave: closeWithDelay,
        'aria-describedby': isOpen ? tooltipId : undefined,
      });

      return popper.getReferenceNodeProps(triggerProps, ref);
    },
    [isOpen, tooltipId, popper, onClick, openWithDelay, closeWithDelay]
  );

  const getTooltipPositionerProps = useCallback(
    (props = {}, ref = null) => {
      const positionerProps = mergeProps(props, {
        style: {
          ...props.style,
          transformOrigin: popper.transformOrigin,
        },
      });

      return popper.getPopperNodeProps(positionerProps, ref);
    },
    [popper]
  );

  const getTooltipProps = useCallback(
    (props = {}, ref = null) => {
      return mergeProps(props, {
        ref,
        id: tooltipId,
        role: 'tooltip',
        style: {
          position: 'relative',
          transformOrigin: popperCSSVars.transformOrigin.varRef,
        },
      });
    },
    [tooltipId]
  );

  useUnmountEffect(() => {
    clearTimeout(openTimeoutRef.current);
    clearTimeout(closeTimeoutRef.current);
  });

  return {
    isOpen,
    placement: popper.placement,
    transformOrigin: popper.transformOrigin,
    getTriggerProps,
    getTooltipPositionerProps,
    getTooltipProps,
  };
}

export type UseTooltipReturn = ReturnType<typeof useTooltip>;
