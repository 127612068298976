import { ReactNode } from 'react';
import { FiX } from 'react-icons/fi';

import { IconButton } from 'components/button';
import { useModalContext } from 'components/modal';
import { Slide } from 'components/transitions';
import { x } from 'style';

export type DrawerContentProps = {
  children?: ReactNode;
};

export function DrawerContent(props: DrawerContentProps) {
  const { children } = props;

  const { isOpen, onClose, getDialogProps } = useModalContext();

  return (
    <Slide
      in={isOpen}
      direction="left"
      boxShadow="lg"
      position="relative"
      display="flex"
      maxW="24vw"
      bg="white"
      h="100%"
      w="100%"
      {...getDialogProps()}
    >
      <x.div flexDirection="column" alignItems="center" flexShrink={0} display="flex" py={6} w={16}>
        <IconButton onClick={onClose}>
          <FiX />
        </IconButton>
      </x.div>

      <x.div flex="1 1 auto" display="flex" flexDirection="column">
        {children}
      </x.div>
    </Slide>
  );
}
