import { useCallback, useMemo } from 'react';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';

import * as preferences from 'api/preferences';
import { ApplicationId, Preference } from 'interfaces';

/**
 * Hook that queries the user preferences.
 * @param options Additional `UseQueryOptions`.
 * @returns The preferences data.
 */
export function usePreferencesQuery(options: UseQueryOptions<Preference[]> = {}) {
  const { data } = useQuery('preferences', () => preferences.getPreferences(), {
    // staleTime: 5 * 1000,
    ...options,
  });

  return {
    preferences: data!,
  };
}

export type UseUpdatePreferencesMutationPayload = preferences.UpdatePreferencesPayload;

/**
 * Hook that returns a mutation used to update the user preferences. The mutation will preform an optimistic update of the user preferences
 * and resync with the server.
 * @returns
 */
export function useUpdatePreferencesMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UseUpdatePreferencesMutationPayload) => preferences.updatePreferences(payload),
    {
      onMutate: (payload) => {
        queryClient.setQueryData<Preference[]>('preferences', (prevValue) => {
          const nextValue = [...(prevValue || [])];
          payload.preferences.forEach((item) => {
            const index = nextValue.findIndex(
              (x) => x.key === item.key && x.applicationId === item.applicationId
            );

            if (index !== -1) {
              nextValue[index] = { ...nextValue[index], value: item.value };
            } else {
              nextValue.push({
                key: item.key,
                value: item.value,
                applicationId: item.applicationId ?? null,
              });
            }
          });

          return nextValue;
        });
      },
    }
  );
}

type UsePreferenceStateOptions<T extends string> = {
  key: string;
  applicationId?: number | null;
  defaultValue?: T | null;
};

export function usePreferenceState<T extends string = string>({
  key,
  applicationId = null,
  defaultValue = null,
}: UsePreferenceStateOptions<T>) {
  const { preferences } = usePreferencesQuery();
  const updatePreferenceMutation = useUpdatePreferencesMutation();

  const value = useMemo(
    () =>
      preferences.find((x) => x.key === key && x.applicationId === applicationId)?.value ?? null,
    [applicationId, key, preferences]
  );

  const setValue = useCallback(
    (newValue: string | null) => {
      if (value !== newValue) {
        updatePreferenceMutation.mutate({
          preferences: [
            {
              key,
              value: newValue,
              applicationId: applicationId,
            },
          ],
        });
      }
    },
    [applicationId, key, value, updatePreferenceMutation]
  );

  return [value || defaultValue, setValue] as [T, (newValue: string | null) => void];
}

export function useDefaultPagePreferenceState(applicationId: ApplicationId | null) {
  return usePreferenceState({
    key: 'defaultPage',
    defaultValue: 'dashboard',
    applicationId,
  });
}
