import { ReactNode } from 'react';

import { x } from 'style';

export interface TabPanelProps {
  children?: ReactNode;
}

export function TabPanel(props: TabPanelProps) {
  const { children } = props;

  return (
    <x.div role="tabpanel" flexGrow={1}>
      {children}
    </x.div>
  );
}
