import { forwardRef } from 'react';

import { HTMLProps, x } from 'style';

export interface FieldLabelProps extends HTMLProps<'label'> {}

export const FieldLabel = forwardRef<HTMLLabelElement, FieldLabelProps>(function FieldLabelWithRef(
  props,
  ref
) {
  const { children, ...otherProps } = props;

  return (
    <x.label ref={ref} color="gray-700" fontWeight="medium" display="block" mb={2} {...otherProps}>
      {children}
    </x.label>
  );
});
