import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface CardContentProps extends StyledProps {}

export const CardContent = forwardRef<HTMLDivElement, CardContentProps>(function CardContent(
  props,
  ref
) {
  return <x.div ref={ref} p={6} {...props} />;
});
