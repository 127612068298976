import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface CardSubtextProps extends StyledProps {}

export const CardSubtext = forwardRef<HTMLDivElement, CardSubtextProps>(function CardSubtext(
  props,
  ref
) {
  return <x.div ref={ref} fontSize="sm" color="gray-500" pb={0} mt={1} {...props} />;
});
