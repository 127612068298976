import { Modal, ModalOverlay, ModalProps } from 'components/modal';

import { DialogContainer } from './DialogContainer';
import { DialogSize } from './types';

export type DialogProps = ModalProps & {
  /**
   * The size of the dialog. Defaults to `md`.
   */
  size?: DialogSize;
};

export function Dialog(props: DialogProps) {
  const { children, size, ...rest } = props;

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <DialogContainer size={size}>{children}</DialogContainer>
    </Modal>
  );
}
