import { ReactElement, cloneElement, forwardRef } from 'react';

import { useCloseDialog } from './hooks';

type DialogCloseProps = {
  children?: ReactElement<HTMLButtonElement>;
};

export const DialogClose = forwardRef<HTMLButtonElement, DialogCloseProps>(
  (props, forwardedRef) => {
    const close = useCloseDialog();

    if (!props.children) {
      return null;
    }

    return cloneElement(props.children, {
      ref: forwardedRef,
      type: 'button',
      onClick: close,
    });
  }
);
