import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface ListItemProps extends StyledProps {}

export const ListItem = forwardRef<HTMLDivElement, ListItemProps>(function ListItemWithRef(
  props,
  ref
) {
  return (
    <x.div
      ref={ref}
      flexDirection="row"
      alignItems="center"
      padding={3}
      display="flex"
      gap={4}
      {...props}
    />
  );
});
