import { forwardRef } from 'react';

import { Stack } from 'components/layout';
import { StyledProps, x } from 'style';

export type ListItemMetaProps = StyledProps & {
  stack?: boolean;
};

/**
 * Component used to display list item metadata.
 */
export const ListItemMeta = forwardRef<HTMLDivElement, ListItemMetaProps>(function ListItemMeta(
  props,
  ref
) {
  const { stack = true, ...otherProps } = props;

  const sharedProps = {
    ref,
    lineHeight: 5,
    fontWeight: 'thin',
    fontSize: 'sm',
    color: 'gray-500',
    mt: 1,
    ...otherProps,
  };

  return stack ? <Stack gap={2} {...sharedProps} /> : <x.div {...sharedProps} />;
});
