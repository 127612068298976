import { HTMLProps, RefObject, useCallback, useRef } from 'react';

import { Placement, useOpenState, useOutsideClick, usePopper, useUnmountEffect } from 'hooks';
import { useIds } from 'hooks/useId';
import { mergeProps, mergeRefs } from 'utils';

export type PopoverPlacement = Placement;

export type UsePopoverBaseOptions = {
  /**
   * The popover id.
   */
  id?: string;

  /**
   * The popover id prefix. Defaults to 'popover'.
   */
  idPrefix?: string;

  /**
   * The placement of the popover.
   */
  placement?: PopoverPlacement;

  /**
   * Reference to the popover boundary element.
   */
  boundaryRef?: RefObject<HTMLElement>;

  /**
   * Whether the popover is open.
   */
  isOpen?: boolean;

  /**
   * Whether the popover initially open.
   */
  defaultOpen?: boolean;

  /**
   * The trigger method used to control the popover. Defaults to 'hover'.
   */
  trigger?: 'hover' | 'click';

  /**
   * Callback function called when the popover is closed.
   */
  onClose?(): void;
};

export function usePopoverBase(opts: UsePopoverBaseOptions) {
  const { id, idPrefix = 'popover', trigger = 'hover', placement, boundaryRef } = opts;

  const contentRef = useRef<HTMLElement>(null);
  const triggerRef = useRef<HTMLElement>(null);

  const { isOpen, open, close, toggle } = useOpenState(opts);
  const popper = usePopper({
    placement,
    boundaryRef,
  });

  const [triggerId, contentId] = useIds(id, `${idPrefix}-trigger`, `${idPrefix}-content`);

  useOutsideClick({
    ref: contentRef,
    handler(event) {
      if (
        isOpen &&
        trigger === 'click' &&
        !triggerRef.current?.contains(event!.target as HTMLElement)
      ) {
        close();
      }
    },
  });

  const getTriggerProps = useCallback(
    (props = {}, ref = null) => {
      return popper.getReferenceNodeProps(
        mergeProps(props, {
          id: triggerId,
          'aria-haspopup': 'dialog',
          'aria-expanded': isOpen,
          'aria-controls': contentId,
        }),
        mergeRefs(triggerRef, ref)
      );
    },
    [contentId, isOpen, popper, triggerId]
  );

  const getContainerProps = useCallback(
    (props = {}, ref = null) => {
      return popper.getPopperNodeProps(
        mergeProps(props, {
          style: {
            minWidth: 'max-content',
            inset: '0 auto auto 0',
          },
        }),
        ref
      );
    },
    [popper]
  );

  return {
    isOpen,
    popper,
    contentId,
    contentRef,
    triggerId,
    triggerRef,
    open,
    close,
    toggle,
    getContainerProps,
    getTriggerProps,
  };
}

export type UsePopoverOptions = UsePopoverBaseOptions & {
  /**
   * The delay when opening the popover
   */
  openDelay?: number;

  /**
   * The delay when closing the popover.
   */
  closeDelay?: number;

  /**
   * Whether the popover should be shown on focus.
   */
  openOnFocus?: boolean;
};

export function usePopover(opts: UsePopoverOptions = {}) {
  const { trigger = 'hover', openDelay = 200, closeDelay = 150, openOnFocus = true } = opts;

  const isHoveringRef = useRef<boolean>(false);
  const openTimeoutRef = useRef<number>();
  const closeTimeoutRef = useRef<number>();

  const {
    isOpen,
    popper,
    contentId,
    contentRef,
    close,
    open,
    toggle,
    getTriggerProps,
    getContainerProps,
  } = usePopoverBase(opts);

  // Clear any active timeouts to ensure state isn't updated after the component has unmounted.
  useUnmountEffect(() => {
    window.clearTimeout(openTimeoutRef.current);
    window.clearTimeout(closeTimeoutRef.current);
  });

  const getPopoverTriggerProps = useCallback(
    (props = {}, ref = null) => {
      const triggerProps: HTMLProps<HTMLElement> = {};

      if (trigger === 'click') {
        triggerProps.onClick = () => toggle();
      } else {
        if (openOnFocus) {
          triggerProps.onFocus = () => open();
          triggerProps.onBlur = () => close();
        }

        triggerProps.onMouseEnter = () => {
          isHoveringRef.current = true;
          openTimeoutRef.current = window.setTimeout(open, openDelay);
        };

        triggerProps.onMouseLeave = () => {
          isHoveringRef.current = false;

          if (openTimeoutRef.current) {
            window.clearTimeout(openTimeoutRef.current);
            openTimeoutRef.current = undefined;
          }

          closeTimeoutRef.current = window.setTimeout(() => {
            if (isHoveringRef.current === false) {
              close();
            }
          }, closeDelay);
        };
      }

      return getTriggerProps(mergeProps(props, triggerProps), ref);
    },
    [closeDelay, openDelay, openOnFocus, trigger, close, getTriggerProps, open, toggle]
  );

  const getPopoverProps = useCallback(
    (props = {}, ref = null) => {
      const popoverProps: HTMLProps<HTMLElement> = {
        ref: mergeRefs(contentRef, ref),
        id: contentId,
        role: 'dialog',
        // tabIndex: -1,
        style: {
          ...props.style,
          transformOrigin: popper.transformOrigin,
        },
      };

      if (trigger === 'click') {
      } else {
        popoverProps.role = 'tooltip';

        popoverProps.onMouseEnter = () => (isHoveringRef.current = true);

        popoverProps.onMouseLeave = () => {
          isHoveringRef.current = false;
          window.setTimeout(close, closeDelay);
        };
      }

      return mergeProps(props, popoverProps);
    },
    [closeDelay, popper, contentRef, contentId, trigger, close]
  );

  return {
    /**
     * Wether the popover is open.
     */
    isOpen,

    /**
     * Opens the popover.
     */
    open,

    /**
     * Closes the popover.
     */
    close,

    /**
     * Gets the popover element props.
     */
    getPopoverProps,

    /**
     * Gets the popover trigger element props.
     */
    getTriggerProps: getPopoverTriggerProps,

    /**
     * Gets the popover container element props.
     */
    getContainerProps,
  };
}

export type UsePopoverReturn = ReturnType<typeof usePopover>;
