import { format, parseJSON } from 'date-fns';

export type DateFormatAlias =
  | 'short'
  | 'medium'
  | 'long'
  | 'full'
  | 'shortDate'
  | 'mediumDate'
  | 'longDate'
  | 'fullDate';

const formatAliases: Record<DateFormatAlias, string> = {
  short: 'M/d/yy, h:mm a',
  medium: 'MMM d, y, h:mm:ss a',
  long: 'MMMM d, y, h:mm:ss a z',
  full: 'EEEE, MMMM d, y, h:mm:ss a zzzz',
  shortDate: 'M/d/yy',
  mediumDate: 'MMM d, y',
  longDate: 'MMMM d, y',
  fullDate: 'EEEE, MMMM d, y',
};

/**
 * Parses the given date.
 * @param date The date to parse.
 * @returns A new `Date` object.
 */
export function parseDate(date: string | number | Date): Date {
  return parseJSON(date);
}

/**
 * Formats a date.
 * @param date The date to format.
 * @param dateFormat The date format.
 * @returns The formatted date.
 */
export function formatDate(
  date: string | number | Date,
  dateFormat: DateFormatAlias | string = 'mediumDate'
): string {
  const alias = formatAliases[dateFormat as DateFormatAlias];
  dateFormat = alias || dateFormat;

  const parsedDate = parseDate(date);
  return format(parsedDate, dateFormat);
}
