import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { authClient } from './AuthClient';
import { AuthContextProvider } from './AuthContext';

export interface AuthProviderProps {
  children?: ReactNode;
}

export function Auth(props: AuthProviderProps) {
  const { children = null } = props;

  const [ready, setReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function authenticate() {
      const { href, pathname } = window.location;

      if (href.includes('/login-callback')) {
        let redirectUrl = '/';
        try {
          const result = await authClient.completeSignIn(href);
          redirectUrl = result?.state?.redirectUrl || '/';
        } catch (e) {}

        navigate(redirectUrl);
      } else {
        const isAuthenticated = await authClient.checkAuthenticated();
        if (!isAuthenticated) {
          const result = await authClient.signIn({ redirectUrl: pathname });
          setReady(result);
          return;
        }
      }

      setReady(true);
    }

    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContextProvider value={{ client: authClient }}>
      {ready ? children : null}
    </AuthContextProvider>
  );
}
