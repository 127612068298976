import { MutableRefObject, RefObject, useEffect, useLayoutEffect } from 'react';

export type UseResizeObserverOptions = {
  invokeOnMount?: boolean;
  onResize(elementRect?: DOMRectReadOnly): void;
};

export function useResizeObserver<T extends HTMLElement>(
  ref: RefObject<T | null> | MutableRefObject<T | null>,
  options: UseResizeObserverOptions
) {
  const { invokeOnMount = false, onResize } = options;

  useEffect(() => {
    let element = ref?.current;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries.length) {
        onResize(entries[0].contentRect);
      }
    });

    resizeObserver.observe(element);

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [ref, onResize]);

  useLayoutEffect(() => {
    if (invokeOnMount) {
      onResize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
