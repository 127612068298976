import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';

import { ApplicationList } from 'components/application-list';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Container } from 'components/layout';
import { useApplicationsQuery } from 'data/applications';

export const Applications = () => {
  const { applications } = useApplicationsQuery();

  if (applications.length === 1) {
    return <Navigate to={`/${applications[0].fullPath}`} />;
  } else if (new Set(applications.map((x) => x.organization.id)).size === 1) {
    return <Navigate to={`/orgs/${applications[0].organization.path}`} />;
  }

  return (
    <>
      <Helmet>
        <title>Applications</title>
      </Helmet>

      <Container py={4}>
        <Breadcrumbs>
          <Breadcrumb>Home</Breadcrumb>
          <Breadcrumb>Applications</Breadcrumb>
        </Breadcrumbs>

        <ApplicationList applications={applications} />
      </Container>
    </>
  );
};
