import { ElementType, forwardRef } from 'react';

import { Spinner } from 'components/spinner';
import { HTMLProps, StyledProps, useVariantStyles, x } from 'style';

export type ButtonVariant = 'primary' | 'danger' | 'warning' | 'link';

export type ButtonSize = 'small' | 'large';

const buttonVariants: Record<ButtonVariant, StyledProps> = {
  primary: {
    color: 'white',
    bg: {
      _: 'primary-500',
      hover: 'primary-600',
      active: 'primary-700',
    },
  },
  danger: {
    color: 'white',
    bg: {
      _: 'danger-500',
      hover: 'danger-600',
      active: 'danger-700',
    },
  },
  warning: {
    color: 'white',
    bg: {
      _: 'warning-500',
      hover: 'warning-600',
      active: 'warning-700',
    },
  },
  link: {
    color: 'primary-500',
    padding: 0,
    outline: {
      _: 0,
      focus: 0,
    },
  },
};

const buttonSizes: Record<ButtonSize, StyledProps> = {
  small: {
    fontSize: 'sm',
    px: 2,
    py: 1,
  },
  large: {
    fontSize: 'lg',
    px: 4,
    py: 3,
  },
};

export interface ButtonProps extends HTMLProps<'button'> {
  as?: ElementType<any>;

  /**
   * The button variant.
   */
  variant?: ButtonVariant;

  /**
   * The button size.
   */
  size?: ButtonSize;

  /**
   * Whether the button is disabled.
   */
  isDisabled?: boolean;

  /**
   * Whether the button is in a pending state.
   */
  isPending?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const {
    as: ElementType,
    children,
    variant,
    size,
    isDisabled = false,
    isPending = false,
    ...otherProps
  } = props;

  const sizeProps = useVariantStyles(size, buttonSizes);
  let variantProps = useVariantStyles(variant, buttonVariants);

  if (isDisabled || isPending) {
    variantProps = {
      ...variantProps,
      disabled: true,
      'aria-disabled': true,
      cursor: 'default',
      opacity: 0.6,
      bg: {
        ...variantProps.bg,
        hover: undefined,
        active: undefined,
      },
    };
  }

  return (
    <x.button
      as={ElementType}
      ref={ref}
      textDecoration="none"
      justifyContent="center"
      borderRadius="md"
      alignItems="center"
      lineHeight={6}
      userSelect="none"
      whiteSpace="nowrap"
      fontWeight="400"
      transition="background-color 100ms ease-in-out"
      position="relative"
      display="inline-flex"
      outline={0}
      border="1px solid transparent"
      color="gray-700"
      bg="transparent"
      px={3}
      py={2}
      {...sizeProps}
      {...variantProps}
      {...otherProps}
    >
      {isPending ? <Spinner size="1em" isIndeterminate marginRight={'0.75em'} /> : null}

      {children}
    </x.button>
  );
});
