import { MouseEvent, RefObject, useCallback } from 'react';

import { PopoverPlacement, UsePopoverOptions, usePopover } from 'components/popover';

import { useMenuContext } from './MenuContext';

export type MenuPlacement = PopoverPlacement;

export type UseMenuOptions = {
  trigger?: 'click' | 'hover';
  placement?: MenuPlacement;
  boundaryRef?: RefObject<HTMLElement>;
  onClose?(): void;
};

export function useMenu(props: UseMenuOptions = {}) {
  const { boundaryRef, placement = 'bottom-start', trigger = 'click', onClose } = props;

  const parentMenu = useMenuContext();
  const parentPopoverProps: UsePopoverOptions = parentMenu
    ? {
        trigger: 'hover',
        placement: 'right-end',
        openDelay: 0,
        closeDelay: 50,
      }
    : {};

  const popover = usePopover({
    trigger,
    placement,
    boundaryRef,
    onClose,
    ...parentPopoverProps,
  });

  return {
    ...popover,
    renderInPortal: !parentMenu,
  };
}

export type UseMenuReturn = ReturnType<typeof useMenu>;

export type UseMenuItemOptions = {
  closeOnClick?: boolean | ((e: MouseEvent) => boolean);
  isDisabled?: boolean;
  onClick?(e: MouseEvent): void;
};

export function useMenuItem(props: UseMenuItemOptions = {}) {
  const { onClick: onClickProp, isDisabled = false, closeOnClick = true } = props;
  const { close } = useMenuContext();

  const onClick = useCallback(
    (e: MouseEvent) => {
      if (!isDisabled) {
        onClickProp?.(e);
      }

      if ((typeof closeOnClick === 'function' && closeOnClick(e)) || closeOnClick === true) {
        close();
      }
    },
    [closeOnClick, isDisabled, close, onClickProp]
  );

  return {
    onClick,
    role: 'menuitem',
    tabIndex: -1,
    'aria-disabled': isDisabled ? ('true' as const) : undefined,
  };
}

export type UseMenuItemReturn = ReturnType<typeof useMenuItem>;
