import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface StackProps extends StyledProps {
  spaceBetween?: boolean;
}

export const Stack = forwardRef<HTMLDivElement, StackProps>(function Stack(props, ref) {
  const { gap = 6, spaceBetween = false, ...otherProps } = props;

  return (
    <x.div
      ref={ref}
      justifyContent={spaceBetween ? 'space-between' : undefined}
      flexDirection="row"
      alignItems="center"
      display="flex"
      gap={gap}
      {...otherProps}
    />
  );
});
