import { forwardRef } from 'react';

import { StyledProps, x } from 'style';

export interface PopoverTitleProps extends StyledProps {}

export const PopoverTitle = forwardRef<HTMLHeadingElement, PopoverTitleProps>(function PopoverTitle(
  props,
  ref
) {
  return <x.h3 ref={ref} fontSize="base" fontWeight="medium" {...props} />;
});
