import { ReactNode } from 'react';

import { Button } from 'components/button';
import { Card } from 'components/card';
import { List } from 'components/list';
import { StyledProps, x } from 'style';

import { useEntityListContext } from './EntityListContext';

export interface EntityListProps<T> extends StyledProps {
  children: (item: T, index?: number) => ReactNode;
}

export function EntityList<T>(props: EntityListProps<T>) {
  const { children: renderFn, ...rest } = props;
  const { items, isFiltered, resetQuery } = useEntityListContext();

  if (!items.length) {
    return (
      <x.div textAlign="center" color="gray-500" py={12}>
        No items to display.{' '}
        {isFiltered ? (
          <Button variant="link" onClick={resetQuery}>
            Clear current query
          </Button>
        ) : null}
      </x.div>
    );
  }

  return (
    <Card boxShadow="sm" {...rest}>
      <List>{items.map((item, index) => renderFn(item, index))}</List>
    </Card>
  );
}
