import { atom, atomFamily, selector, useRecoilValue } from 'recoil';

import { getApplication } from 'api/applications';
import { getOrganization } from 'api/organizations';
import { Application, ApplicationId, Organization, WorkflowId } from 'interfaces';
import { createLocalStorageEffect } from 'utils';

import { queryClient } from './queryClient';

export const applicationPathState = atom<string | null>({
  key: 'currentApplicationPath',
  default: null,
});

export const currentApplication = selector<Application | null>({
  key: 'currentApplication',
  get: async ({ get }) => {
    const appPath = get(applicationPathState);
    if (!appPath) {
      return null;
    }

    const application = await queryClient.fetchQuery(
      ['applications', appPath],
      () => getApplication(appPath),
      {
        initialData: () =>
          queryClient
            .getQueryData<Application[]>('applications')
            ?.find((x) => x.slug === appPath.toLocaleLowerCase()),
      }
    );

    return application;
  },
});

export function useCurrentApplication(strict = true): Application {
  const application = useRecoilValue(currentApplication);
  if (!application && strict) {
    throw new Error(`Current application should not be null.`);
  }

  return application!;
}

export const organizationPathState = atom<string | null>({
  key: 'currentOrganizationPath',
  default: null,
});

export const currentOrganization = selector<Organization | null>({
  key: 'currentOrganization',
  get: async ({ get }) => {
    const orgPath = get(organizationPathState);
    if (!orgPath) {
      return null;
    }

    const organization = await queryClient.fetchQuery(
      ['organizations', orgPath],
      () => getOrganization(orgPath),
      {
        initialData: () =>
          queryClient
            .getQueryData<Organization[]>('organizations')
            ?.find((x) => x.path === orgPath.toLocaleLowerCase()),
      }
    );

    return organization;
  },
});

export function useCurrentOrganization(strict = true): Organization {
  const organization = useRecoilValue(currentOrganization);
  if (!organization && strict) {
    throw new Error(`Current organization should not be null.`);
  }

  return organization!;
}

export type CurrentWorkflowIdParam = {
  applicationId: ApplicationId;
};

export const currentWorkflowIdState = atomFamily<WorkflowId | null, CurrentWorkflowIdParam>({
  key: 'currentWorkflowId',
  default: null,
  effects_UNSTABLE: (params) => [
    createLocalStorageEffect(`currentWorkflow.${params.applicationId}`),
  ],
});
